<template>
  <Header :user='user' :categories='categories' :cartCourses="cartCourses" @logout="logout" @loadCart="loadCourses" :announcement="about.announcement" :phones="about.phones"/>
  <router-view v-bind="props"/>
  <Footer footerPadding="true" :social="about.social" :phones="about.phones"/>
</template>

<script>
import api from '@/api'
import Header from '@/components/Home/Header.vue';
import Footer from '@/components/Home/Footer.vue';

export default {
  name: 'App',
  components:{
    Header,
    Footer
  },
  computed:{
    props(){
      let res = {}
      if(this.$route.name == 'Home' || this.$route.name == 'Search'){
        res = { categories: this.categories };
      }
      if (this.$route.name == 'Home'){
        res.phone = this.about.phones
        res.offers = this.about.offers
        res.sponsorships = this.about.sponsorships
      }
      if (this.$route.name == "SignUp" || this.$route.name == "Login"){
        res = { user: this.user };
      }
      if (this.$route.name == 'Cart'){
        res = { cartCourses: this.cartCourses }
      }
      return res;
    }
  },
  data(){
    return {
      cartCourses: [],
      categories:[],
      tags:[],
      user:null,
      about:{}
    }
  },
  methods:{
    async loadCategories(){
      const res=await fetch(`${api.Url}/api/categories`, {
        method: 'GET',
      });
      return await res.json();
    },
    async loadTags(){
      const res= await fetch(`${api.Url}/api/tags`, {
        method: 'GET',
      });
      return await res.json();
    },
    removeFromCart(id){
      let newCart = [];
      let idx = -1;
      this.cartCourses.forEach((course, i)=>{
        if (course._id == id){
          idx = i;
        } else {
          newCart.push(course._id)
        }
      })
      localStorage.setItem('cart' ,JSON.stringify(newCart))
      this.cartCourses.splice(idx, 1)
    },
    removeNotFoundCart(courses){
      let x = [];
      courses = courses || [];
      courses.forEach(course => {
        x.push(course._id)
      });
      localStorage.setItem('cart', JSON.stringify(x));
    },
    addToCart(course){
      //check if already in cart and if cartCourses is loaded or not
      let idx;
      let loaded = this.cartCourses?.length && this.cartCourses[0]?._id;
      if (loaded){
        idx = this.cartCourses.findIndex((x) => x._id == course._id)
        if (idx == -1){
          //if loaded and not found  push whole object
          this.cartCourses.push(course)
          const newCart = this.cartCourses.map(x => x._id)
          localStorage.setItem('cart', JSON.stringify(newCart))
        }
      } else {
        idx = this.cartCourses.findIndex((x) => x == course._id)

        if (idx == -1){
          //if not loaded and not found  push only id
          this.cartCourses.push(course._id)
          localStorage.setItem('cart', JSON.stringify(this.cartCourses))
        }
      }
    },
    async loadCourses(){
      let res=await fetch(`${api.Url}/api/courses?` + new URLSearchParams({
        ids: this.cartCourses
      }), {
        method: 'GET',
      });
      res = await res.json();
      console.log(res)
      if (res.courses?.length != this.cartCourses?.length)
        this.removeNotFoundCart(res.courses)
      this.cartCourses = res.courses || [];
    },
    async loadUser(){
      const res=await fetch(`${api.Url}/api/users/me`, {
        method: 'GET',
        headers: api.headers()
      });
      return await res.json();
    },
    logout(){
      this.user = null;
      localStorage.setItem('jwt','')
      this.$router.push({name:'Login'})
    },
    async loadAbout(){
      const res=await fetch(`${api.Url}/api/about`, {
        method: 'GET',
      });
      return await res.json();
    }
  },
  created(){
    //Cart
    try {
      let x = JSON.parse(localStorage.getItem('cart')) || []
      if (Array.isArray(x)){
        this.cartCourses = x;
      } else {
        this.cartCourses = []
      }
    } catch(e) {
        this.cartCourses = []
    }
  },
  mounted(){
    //loading categories and about
    const loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          color: "#2b4eff",
          blur: "",
          opacity: 1,
          loader:'bars',
          lockScroll:true
    });
    Promise.all([
      this.loadUser(), 
      this.loadCategories(),
      this.loadAbout(),
      this.loadTags()
    ])
    .then((value)=>{
      this.user = value[0].user
      this.categories = value[1].categories,
      this.about = value[2].about
      this.tags = value[3].tags
      console.log(value[3].tags)
      if (this.user && !this.user.isCompleted)
        this.$router.push({name: 'Dashboard'})
      
      loader.hide();
    })
    .catch(err=>{
      loader.hide();

      throw err;
    })
    

  }
}
</script>


