<template>
  <LoginArea/>
</template>

<script>
import LoginArea from '../components/SignIn/LoginArea.vue';

export default {
  name:'SignIn',
  components:{
    LoginArea,
  },
  props:['user'],
  watch:{
    user(newVal){ // if you go to login page directly then you will have user == null because it is still loading so watch it
      if (newVal){
        this.$router.replace({name:"Dashboard"})
      }
    }
  },
  mounted(){
    if (this.user)
      this.$router.replace({name:"Dashboard"})
  }
}
</script>

