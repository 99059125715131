<template>
    <div v-if='show' class="alert alert-warning text-center pt-5 pb-5 mb-0" role="alert">
        <a :href="announcement.url">{{announcement['english']}}</a>
        <button type="button" class="btn-close" style="float:right" @click="hide"></button>
    </div>
</template>

<script>
export default {
    name: 'HeaderAnnouncement',
    props: ['announcement'],
    data(){
        return {
            show:true,
        }
    },
    methods:{
        hide(){
            this.show = false;
            localStorage.setItem('closedAnnouncement', this.announcement.id)
        }
    },
    mounted(){
        let closed = localStorage.getItem('closedAnnouncement') || ''
        if (closed == this.announcement.id){
            this.show = false
        }
    }
}
</script>

<style>

</style>