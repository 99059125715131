<template>
    <div class="share-on-social d-xl-flex align-items-center">
        <h4>Share:</h4>
        <ul>
            <li><a :href="'https://www.facebook.com/sharer/sharer.php?u=https://facebook.com'" class="fb" target="_blank">
                <i class="fab fa-facebook-f"></i>
            </a></li>
            <li><a :href="'http://twitter.com/share?text=Join Us&url='+link" class="tw" >
                <i class="fab fa-twitter"></i>
            </a></li>
            <li><a href="" @click.prevent="copyLink" class="copy" >
                <i class="far fa-copy"></i>
            </a></li>
        </ul>
    </div>
</template>
<script>
export default {
    computed:{
        link(){
            return window.location.href
        }
    },
    methods:{
        copyLink(){
            navigator.clipboard.writeText(this.link);
            alert("Copied link to Clipboard");
        }
    }
}
</script>
