<template>
  <InstructorDetailsArea/>
</template>

<script>
import InstructorDetailsArea from '../components/InstructorDetails/InstructorDetailsArea.vue';

export default {
    name:'InstructorDetails',
    components:{
        InstructorDetailsArea,
    }
}
</script>

