<template>
    <section class="course__area pt-115 pb-120 grey-bg">
            <div class="container">
               <div class="row align-items-end">
                  <div class="col-xxl-5 col-xl-6 col-lg-6">
                     <div class="section__title-wrapper mb-60">
                        <h2 class="section__title">Popular <span class="yellow-bg yellow-bg-big">Online Courses<img src="/img/shape/yellow-bg.png" alt=""></span></h2>
                        <p>You don't have to struggle alone, you've got our assistance and help.</p>
                     </div>
                  </div>
                  <div class="col-xxl-7 col-xl-6 col-lg-6">
                     <div class="course__menu d-flex justify-content-lg-end mb-60">
                        <div class="masonary-menu filter-button-group">
                           <nav>
                              <div className="nav nav-tabs justify-content-center" id="portfolio-tab" role="tablist">
                                 <button className="nav-link" @click="()=>{selectedTabId = 'all'}" :class="{'active': selectedTabId == 'all'}">See All <span class="tag">new</span> </button>

                                 <button 
                                    className="nav-link"
                                    :class="{'active': selectedTabId == cat.id}"
                                    v-for="cat in categories.slice(0,5)"
                                    :key='cat.id'
                                    @click="selectedTabId = cat.id"
                                 >
                                 {{ cat.name }}</button>
                              </div>
                           </nav>

                       </div>
                     </div>
                  </div>
               </div>
               <div class="row grid tab-content">
                  <div className="tab-pane fade show active" ref="formCourse">
                     <CourseItems
                        :catId="selectedTabId"
                        :courses="courses[selectedTabId]" 
                        @load="load"/>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
import CourseItems from '../courseItems/CoursesItems.vue';
import api from '@/api'

export default {
   name:'homeCourse',
   components:{CourseItems},
   props:['categories'],
   data(){
      return {
         active:0,
         selectedTabId:'all',
         courses:{}
      }
   },
   methods:{
      load(catId){
         let params = new URLSearchParams({ categories: (catId == 'all' ? '':catId) , order: 'newer'})
         fetch(`${api.Url}/api/courses?` + params,{
            method:'GET',
         })
         .then(res => res.json())
         .then(res => {
            console.log(res)
            if (res.success)
               this.courses[catId] = res.courses
         })
         .catch(err => {
         throw err
         })
      }
   }
};
</script>
