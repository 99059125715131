<template>
<section class="signup__area po-rel-z1 pt-100 pb-145">
   <div class="toast-container">
      <div class="toast align-items-center text-white bg-primary border-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref='toast'>
         <div class="d-flex">
               <div class="toast-body">
                  {{ toastError}}
               </div>
               <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
         </div>
      </div>
   </div>
   <div class="sign__shape">
      <img class="man-1" src="/img/icon/sign/man-3.png" alt="">
      <img class="man-2 man-22" src="/img/icon/sign/man-2.png" alt="">
      <img class="circle" src="/img/icon/sign/circle.png" alt="">
      <img class="zigzag" src="/img/icon/sign/zigzag.png" alt="">
      <img class="dot" src="/img/icon/sign/dot.png" alt="">
      <img class="bg" src="/img/icon/sign/sign-up.png" alt="">
      <img class="flower" src="/img/icon/sign/flower.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
            <div class="section__title-wrapper text-center mb-55">
               <h2 class="section__title">Create a free <br>  Account</h2>
               <p>I'm a subhead that goes with a story.</p>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
            <div class="sign__wrapper white-bg">
               <div class="sign__header mb-35">
                  <div class="sign__in text-center">
                     <a @click.prevent="signUpWithGoogle" href="" class="sign__social g-plus text-start mb-15"><i class="fab fa-google-plus-g"></i>Sign Up with Google</a>
                     <p> <span>........</span> Or, 
                     <router-link to="/register">sign up</router-link> 
                     with your email<span> ........</span> </p>
                  </div>
               </div>
               <div class="sign__form">
                  <form @submit.prevent="submit">
                     <div class="sign__input-wrapper mb-25">
                        <h5>First Name</h5>
                        <div class="sign__input">
                           <input type="text" placeholder="First Name" required v-model.trim="signupForm.firstName">
                           <i class="fal fa-user"></i>
                        </div>
                     </div>
                     <div class="sign__input-wrapper mb-25">
                        <h5>Last Name</h5>
                        <div class="sign__input">
                           <input type="text" placeholder="Last Name" required v-model.trim="signupForm.lastName">
                           <i class="fal fa-user"></i>
                        </div>
                     </div>
                     <div class="sign__input-wrapper mb-25">
                        <h5>Email</h5>
                        <div class="sign__input">
                           <input type="text" placeholder="e-mail address" required v-model.trim="signupForm.email">
                           <i class="fal fa-envelope"></i>
                        </div>
                     </div>
                     <div class="sign__input-wrapper mb-25">
                        <h5>Password</h5>
                        <div class="sign__input">
                           <input type="text" placeholder="Password" required v-model.trim="signupForm.password" minlength="8">
                           <i class="fal fa-lock"></i>
                        </div>
                     </div>
                     <div class="sign__input-wrapper mb-10">
                        <h5>Re-Password</h5>
                        <div class="sign__input">
                           <input type="text" placeholder="Re-Password" required v-model.trim="signupForm.passwordConfirm">
                           <i class="fal fa-lock"></i>
                        </div>
                     </div>
                     <!--
                     <div class="sign__action d-flex justify-content-between mb-30">
                        <div class="sign__agree d-flex align-items-center">
                           <input class="m-check-input" type="checkbox" id="m-agree">
                           <label class="m-check-label" for="m-agree">I agree to the <a href="#">Terms & Conditions</a>
                              </label>
                        </div>
                     </div>
                     -->
                     <button class="e-btn w-100"> <span></span> Sign Up</button>
                     <div class="sign__new text-center mt-20">
                        <p>Already in Socrates? <router-link to="/login"> Sign In</router-link></p>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
import api from '@/api'
import {Toast} from 'bootstrap'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load({monitoring:false, delayFallback:800});

export default {
  name: "SignUpArea",
  data(){
      return {
         toastError:'',
         signupForm:{
         },
      }
  },
  methods:{
   async generateVisitorId(){
         let result = await fpPromise.then(fp => fp.get())
         // eslint-disable-next-line no-unused-vars
         let { fontPreferences, languages, timezone,...components} = result.components;
         if ('value' in components.canvas) {
            components.canvas.value.text = ''
         }
         const visitorId = FingerprintJS.hashComponents(components)
         return visitorId;
   },
   signUpWithGoogle(){
      // Google's OAuth 2.0 endpoint for requesting an access token
      var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      const form = document.createElement('form');
      form.setAttribute('method', 'GET'); // Send as a GET request.
      form.setAttribute('action', oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
         const params = {'client_id': '1063327649994-afefib5tbn1taanh9eldqn26j23u7852.apps.googleusercontent.com',
                        'redirect_uri': api.googleRedirectUrl + '/register?with-google=true',
                        'response_type': 'token',
                        'scope': 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                        'include_granted_scopes': 'true',
                        'prompt': 'select_account'
         };

      // Add form parameters as hidden input values.
      for (var p in params) {
         var input = document.createElement('input');
         input.setAttribute('type', 'hidden');
         input.setAttribute('name', p);
         input.setAttribute('value', params[p]);
         form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
   },
   successSingIn(token, user){
      localStorage.setItem('jwt', token);
      this.$root.$data.user = user;
      this.$router.push({ path: '/'})
   },
   submit(){
      if (this.signupForm.password != this.signupForm.passwordConfirm)
         return;
      fetch(`${api.Url}/api/users/`,{
         method:'POST',
         headers: api.headers(false, true),
         body:JSON.stringify(this.signupForm)
      })
      .then(res=> res.json())
      .then(res=>{
         console.log(res)

         if (res.success){
            this.$router.push({name:"Login"})
         } else {
               this.toastError = res.err || "Failed to Register";
               new Toast(this.$refs.toast).show({delay:1000})
         }
      })
      .catch(err=>{
         this.toastError = "Failed to Register";
         new Toast(this.$refs.toast).show({delay:1000})
         throw err;
      })
   }
  },
  async mounted(){
      if (this.$route.query['with-google'] == 'true'){
         let hash = this.$route.hash.substr(1)
         var result = hash.split('&').reduce(function (res, item) {
            var parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
         }, {});
         let visitorId = await this.generateVisitorId();
         fetch(`${api.Url}/api/users/register/with-google`,{
            method:'POST',
            headers: api.headers(false, true),
            body:JSON.stringify({"accessToken": result.access_token, fingerprint:visitorId})
         })
         .then(res=> res.json())
         .then(res=>{
            if (res.success){
               this.successSingIn(res.token, res.user)
            } else {
               this.toastError = res.err ||"Failed to Register";
               new Toast(this.$refs.toast).show({delay:1000})
            }
         })
         .catch(err=>{
            this.toastError = "Failed to Register";
            new Toast(this.$refs.toast).show({delay:1000})
            throw err;
         })
      }
  }
};
</script>

