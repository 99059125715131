function headers(auth = true, type= true){
    let res = {}
    if (type)
        res['Content-Type'] = 'application/json';
    if (auth)
        res['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')

    return res;
}
const apiConf = {
    Url: (process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : ''),
    googleRedirectUrl: (process.env.NODE_ENV == 'development' ? 'http://localhost:8080' : 'https://socratesademy.com'),
    headers:headers
}
export default apiConf;