<template>
   <Breadcrumb title="Courses" subtitle="Courses" />   
   <CourseSidebarArea :categories='categories'/>
</template>

<script>
import Breadcrumb from '../components/common/Breadcrumb.vue';
import CourseSidebarArea from '../components/CourseSidebar/CourseSidebarArea.vue';

export default {
   name:'CourseSidebar',
   props:['categories'],
   components:{
      Breadcrumb,
      CourseSidebarArea,
   }
};
</script>
