<template>
  <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
   <div class="course__review">
      <h3>Reviews</h3>
      <div class="course__review-rating mb-50">
         <div class="row g-0">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
               <div class="course__review-rating-info grey-bg text-center">
                  <h5>{{ Math.round(course.rating * 10 / course.numberOfRaters) /10 || 0}}</h5>
                  <ul>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                  </ul>
                  <p>{{course.numberOfRaters}} Ratings</p>
               </div>
            </div>
            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
               <div class="course__review-details grey-bg">
                  <h5>Detailed Rating</h5>
                  <div class="course__review-content mb-20">
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>5 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" :style="'width: '+(Math.round(course.rated5 / course.numberOfRaters * 100) || 0)+'%'"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>{{Math.round(course.rated5 / course.numberOfRaters * 100) || 0}}%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>4 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" :style="'width: '+(Math.round(course.rated4 / course.numberOfRaters * 100) || 0)+'%'"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>{{Math.round(course.rated4 / course.numberOfRaters * 100) || 0}}%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>3 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" :style="'width: '+(Math.round(course.rated3 / course.numberOfRaters * 100) || 0)+'%'"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>{{Math.round(course.rated3 / course.numberOfRaters * 100) || 0}}%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>2 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" :style="'width: '+(Math.round(course.rated2 / course.numberOfRaters * 100) || 0)+'%'"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>{{Math.round(course.rated2 / course.numberOfRaters * 100) || 0}}%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>1 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" :style="'width: '+(Math.round(course.rated1 / course.numberOfRaters * 100) || 0)+'%'"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>{{Math.round(course.rated1 / course.numberOfRaters * 100) || 0}}%</h5>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div v-if='reviews.length' class="course__comment mb-75">
         <h3>{{reviews.length}} Reviews</h3>

         <ul>
            <li
               v-for="(review, idx) in reviews"
               :key="idx"
            >
               <div class="course__comment-box ">
                  <div class="course__comment-thumb float-start">
                     <img :src="'/images/users/thumb/' + review.user?.profilePictureUrl" alt="" @error="$userImageErrorHandler">
                  </div>
                  <div class="course__comment-content">
                     <div class="course__comment-wrapper ml-70 fix">
                        <div class="course__comment-info float-start">
                           <h4>{{(review.user.firstName || 'Unknown') + ' ' + (review.user.lastName || '')}}</h4>
                           <span>{{$dateFormat(review.createdAt)}}</span>
                        </div>
                        <div class="course__comment-rating float-start float-sm-end">
                           <ul>
                              <li v-for="n in review.stars" :key="'star' + n"><a href="#"> <i class="fas fa-star"></i> </a></li>
                           </ul>
                        </div>
                     </div>
                     <div v-if="review.review" class="course__comment-text ml-70">
                        <p>{{review.review}}</p>
                     </div>
                  </div>
               </div>
            </li>
         </ul>
      </div>
      <div v-if="(enrolled && !submitted && !reviewed)" class="course__form">
         <h3>Write a Review</h3>
         <div class="course__form-inner">
            <form @submit.prevent="submit">
               <div class="row">
                  <div class="col-xxl-12">
                     <div class="course__form-input">
                        <div class="course__form-rating">
                           <ul>
                              <li
                                 v-for="star in 5"
                                 :key="'star'+star"
                              ><button type="button" @click="newReview.stars = star" :class="{'no-rating':(star > newReview.stars)}"> <i class="fas fa-star"></i> </button></li>
                           </ul>
                        </div>
                        <textarea required placeholder="Review Summary" maxlength="300" v-model.trim="newReview.review"></textarea>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-xxl-12">
                     <div class="course__form-btn mt-10 mb-55">
                        <button type="submit" class="e-btn">Submit Review</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
 </div>
</template>

<script>
import api from '@/api'

export default {
   name:'ReviewTab',
   props:['course', 'enrolled', 'reviewed'],
   data(){
      return {
         reviews:[],
         newReview:{
            review:'',
            stars:3
         },
         submitted:false
      }
   },
   methods:{
      submit(){
         fetch(`${api.Url}/api/reviews/${this.$route.params.id}`,{
            method:'POST',
            headers: api.headers(),
            body:JSON.stringify(this.newReview)
         })
         .then(res=>res.json())
         .then(res=> {
            console.log(res)
            if (res.success){
               this.reviews.push(res.review)
               this.submitted = true
            }
         })
         .catch(err => {
            throw err
         })
      }
   },
   mounted(){
      fetch(`${api.Url}/api/reviews/${this.$route.params.id}`,{
         method:'GET',
         headers: api.headers()
      })
      .then(res=>res.json())
      .then(res=> {
         if (res.success){
            this.reviews = res.reviews
         }
      })
      .catch(err => {
         throw err
      })
   }
};
</script>

