import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css';
import router from './router';
//import 'swiper/css/bundle';
import './assets/css/fontAwesome5Pro.css';

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import './assets/scss/main.scss';
import VueGtag from "vue-gtag";
import api from '@/api'

let app = createApp(App);
app.use(router)
app.use(VueLoading)
app.use(VueGtag, {
  config: { 
    id: "G-K0R761QY8H",
    params: {
      send_page_view: true,
    }
  }
}, router)

app.config.globalProperties.$url = api.Url

app.config.globalProperties.$userImageErrorHandler = (e)=>{
  e.target.src = '/img/user.svg'
  e.target.onerror = null
}
app.config.globalProperties.$dateFormat = (date) => {
  if (date)
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  else
    return '';
}


app.mount('#app');
