<template>
 <div class="col-xxl-4 col-xl-4 col-lg-4">
   <div class="course__sidebar pl-70" :class="{'show' : show}">
      <div class="course__sidebar-search mb-50">
         <input v-model="name" type="text" placeholder="Search for courses...">
      </div>
      <div class="course__sidebar-widget grey-bg">
         <div class="course__sidebar-info">
            <h3 class="course__sidebar-title">Categories</h3>
            <ul>
               <li 
                  v-for="cat in categories"
                  :key='cat.id'
                  class="category-wrapper"
               >
                  <div class="course__sidebar-check mb-10 d-flex align-items-center justify-content-between">
                     <div class="d-flex align-items-center">
                        <input class="m-check-input" @input='(e)=>{update(e, cat.id)}' type="checkbox" :checked="checked[cat.id]">
                        <label class="m-check-label" @click="toggle">{{cat.name}}</label>
                     </div>
                     <button v-if="cat.children && cat.children.length>0" class="expand-button" @click="expandCategories"><i class="far fa-chevron-left"></i></button> 
                  </div>
                  <ul style="padding-left:24px">
                     <li 
                        v-for="sub in cat.children"
                        :key='sub.id'
                        class="category-wrapper">
                        <div class="course__sidebar-check mb-10 d-flex align-items-center justify-content-between">
                           <div class="d-flex align-items-center">
                              <input class="m-check-input" @input='(e)=>{update(e, sub.id)}' type="checkbox" :checked="checked[sub.id]">
                              <label class="m-check-label" @click="toggle">{{sub.name}}</label>
                           </div>
                           <button v-if="sub.children && sub.children.length>0" class="expand-button" @click="expandCategories"><i class="far fa-chevron-left"></i></button> 
                        </div>
                        <ul style="padding-left:28px">
                           <li 
                              v-for="final in sub.children"
                              :key='final.id'
                              class="category-wrapper">
                                 <div class="course__sidebar-check mb-10 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                       <input class="m-check-input" @input='(e)=>{update(e, final.id)}' type="checkbox" :checked="checked[final.id]">
                                       <label class="m-check-label" @click="toggle">{{final.name}}</label>
                                    </div>
                                 </div>
                           </li>
                        </ul>
                     </li>  
                  </ul>
               </li>
            </ul>
         </div>
      </div>
            <div class="course__sidebar-widget grey-bg">
         <div class="course__sidebar-info">
            <h3 class="course__sidebar-title">Tags</h3>
            <ul>
               <li 
                  v-for="tag in $root.tags"
                  :key='tag._id'
                  class="category-wrapper"
               >
                  <div class="course__sidebar-check mb-10 d-flex align-items-center justify-content-between">
                     <div class="d-flex align-items-center">
                        <input class="m-check-input" @input='(e)=>{updateTag(e, tag._id)}' type="checkbox" :checked="checkedTag[tag._id]">
                        <label class="m-check-label">{{tag.title}}</label>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
      </div>
      <button class="full-width e-btn" @click="()=>{ $emit('search', queryCategories, name, queryTags) }">Search</button>
      <button class="full-width close-button e-btn" @click="()=>{ $emit('close') }">Close</button>

   </div>
</div>
</template>

<script>
export default {
   name:'CourseRightSide',
   props:{'show':Boolean, 'categories':Array},
   emits:['close', 'search'],
   data(){
      return {
         name:'',
         queryCategories:[],
         expanded:[],
         checked:{},
         checkedTag:{},
         queryTags:[],
      }
   },
   methods:{
      toggle(e){
         e.target.previousElementSibling.click()
      },
      updateTag(e, tagId){
         if(e.target.checked){
            this.queryTags.push(tagId)
         }
         else{
            const index = this.queryTags.indexOf(tagId);
            if (index > -1)
               this.queryTags.splice(index, 1);
         }                  
      },
      update(e, catId){
         if(e.target.checked){
            this.queryCategories.push(catId)
         }
         else{
            const index = this.queryCategories.indexOf(catId);
            if (index > -1)
               this.queryCategories.splice(index, 1);
         }
      },
      expandCategories(e){
         e.target.parentElement.parentElement.parentElement.classList.toggle('expanded')
      }
   },
   watch:{
      '$route.query.name': {
        handler: function(search) {
            this.name = search
        },
        immediate: true
      }
   },
   mounted(){
      let name = this.$route.query.name
      if(name)
         this.name = name
      else 
         this.name = ''
      //categories
      let ids = [];
      if (this.$route.query.categories)
         ids = this.$route.query.categories
      
      if (!Array.isArray(ids))
         ids = [ids];

      ids.forEach(id => {
         this.checked[id] = true
      })
      this.queryCategories = ids

      //tags
      let tags = [];
      if (this.$route.query.tags)
         tags = this.$route.query.tags
      if (!Array.isArray(tags))
         tags = [tags];
      tags.forEach(tag => {
         this.checkedTag[tag] = true
      })
      this.queryTags = tags

   }
};
/*
   on mounted 
      get list of used categories
      
*/
</script>


