<template>
    <Breadcrumb title="Dashboard" subtitle="Dashboard" />
    <div class="container">
        <div class="row">
            <div class="dashboard mb-45 mt-30 pl-80 pr-80">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <button class="nav-link" :class="{'active': value == 0}" @click="value=0"><i class="far fa-user"></i><span>Profile</span></button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" :class="{'active': value == 1}" @click="value=1"><i class="far fa-lock"></i><span>Password</span></button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" :class="{'active': value == 2}" @click="value=2"><i class="far fa-book"></i><span>Courses</span></button>
                    </li>
                </ul>
                <div class="toast-container">
                    <div class="toast align-items-center text-white bg-primary border-0" :class="[toastStatus? 'bg-success':'bg-danger']" role="alert" aria-live="assertive" aria-atomic="true" ref='dashboardToast'>
                        <div class="d-flex">
                            <div class="toast-body">
                               {{ toastStatus? 'Saved Successfully' : 'Failed to save'}}
                            </div>
                            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <UserDetails v-if="value==0" :details='user' @toast='toast'/>
                <PasswordChange v-if="value==1" :id="user._id" @toast='toast'/>
                <KeepAlive>
                    <UserCourses v-if="value==2" :courses="user.courses"/>
                </KeepAlive>
            </div>
            
        </div>
    </div>
</template>

<script>
import api from '@/api'
import UserDetails from '../components/Dashboard/UserDetails.vue'
import PasswordChange from '../components/Dashboard/PasswordChange.vue'
import UserCourses from '../components/Dashboard/UserCourses.vue'
import Breadcrumb from '../components/common/Breadcrumb.vue';
import {Toast} from 'bootstrap'

export default {
    name:"DashboardArea",
    components: {UserDetails, PasswordChange,UserCourses ,Breadcrumb},
    data(){
        return {
            value:0,
            user:{},
            toastStatus: true
        }
    },
    methods:{
        toast(e){
            this.toastStatus = e
            new Toast(this.$refs.dashboardToast).show({delay:1000})
        }
    },
    mounted(){
        fetch(`${api.Url}/api/users/me?full=true`,{
            method:"GET",
            headers: api.headers(),
        })
            .then(res=>res.json())
            .then(res => {
                console.log(res)
                if (res.success && res.user){
                    this.user= res.user
                }
                else {
                    this.$root.user = null
                    this.$router.replace({name:"Login"})
                }
            })
        this.value = parseInt(this.$route.query.startingTab)
    }
}
</script>

<style>

</style>