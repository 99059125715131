<template>
    <HeroSection :phone="phone" :offers="offers"/>
    <BrandArea :sponsorships="sponsorships"  v-if="sponsorships?.length" />
    <CategoryArea :categories="categories"/>
    <Course :categories="categories"/>
    <Events :home="true"/>
    <Cta/>
</template>

<script>
import HeroSection from '../components/Home/HeroSection.vue';
import BrandArea from '../components/common/BrandArea.vue';
import CategoryArea from '../components/Home/CategoryArea.vue';
import Course from '../components/Home/Course.vue';
import Events from '../components/Home/Events.vue';
import Cta from '../components/Home/Cta.vue'

export default {
    name:'HomePage',
    props:['categories', 'phone', 'offers', "sponsorships"],
    components:{
        HeroSection,
        BrandArea,
        CategoryArea,
        Course,
        Events,
        Cta
    }
 }
 </script>