<template>
   <div class="container">
      <div v-if="!loading" class="row">
         <div v-for="(course,index) in loadedCourses" :key="index" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 grid-item">
            <div class="course__item white-bg mb-30 fix">
               <div class="course__thumb w-img p-relative fix">
                     <router-link :to="`/courses/${course._id}`">
                        <img :src="$url+'/images/courses/' + course.thumbnailUrl" alt="">
                     </router-link>
                  <div v-if="course.label" class="course__tag">
                     <a href="#" :class="colors[index % 7]">{{course.label}}</a>
                  </div>
               </div>
               <div class="course__content">
                  <div class="course__meta d-flex align-items-center justify-content-between">
                     <div class="course__lesson">
                        <span><i class="far fa-book-alt"></i>{{course.lessons}} Lesson</span>
                     </div>
                     <div class="course__rating">
                        <span><i class="fas fa-star"></i>{{(course.rating / course.numberOfRaters || 0)}} ({{course.numberOfRaters}})</span>
                     </div>
                  </div>
                  <h3 class="course__title">
                     <router-link :to="`/courses/${course._id}`">{{course.name}}</router-link>
                  </h3>
                  <div class="course__teacher d-flex align-items-center">
                     <div class="course__teacher-thumb mr-15">
                        <img :src="$url+'/images/users/thumb/'+course.instructorId.profilePictureUrl" alt="">
                     </div>
                     <h6><router-link to="/instructor-details">{{course.instructorId.firstName + ' ' + course.instructorId.lastName}}</router-link></h6>
                  </div>
               </div>
            </div>
         </div>
      <Pagination :pages="(Math.ceil(total/10))" :page="page" @page="loadCourses" class="mb-35"/>
      </div>
      <div v-else style="height:200px;position:relative">
         <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
      </div>
   </div>
</template>

<script>
import Pagination from '../common/Pagination.vue'
import Loading from 'vue-loading-overlay';
import colors from '../../mixins/colors';
import api from '@/api'

export default {
   name:'UserCourses',
   props:['courses'],
   emits:['loadCourses'],
   components:{ Loading, Pagination},
   data(){
      return {
         loadedCourses:null,
         page:0,
         total:0,
         loading:true
      }
   },
   methods:{
      loadCourses(page = 0){
         if(this.courses?.length ==0){
            this.loading = false
            return;
         }
         this.loading = true
         this.page = page;
         let params = new URLSearchParams({ ids: this.courses?.map(e => e.course)});
         fetch(`${api.Url}/api/courses?` + params,{
            method:'GET',
         })
         .then(res => res.json())
         .then(res => {
            this.loading = false;
            if (res.success){
               this.loadedCourses = res.courses
               this.total = res.total
            }
         })
         .catch(err => {
            throw err
         })
      }
   },
   mixins:[colors],
   watch:{
      courses(){
         this.loadCourses();
      }
   },
   mounted(){
      this.loadCourses()
   }
}
</script>

<style>

</style>