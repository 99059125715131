<template>
<div class="col-xxl-4 col-xl-4 col-lg-4">
   <div class="course__sidebar-details pl-70 p-relative">
      <div class="course__shape">
         <img class="course-dot" src="/img/course/course-dot.png" alt="">
      </div>
      <div class="course__sidebar-widget-2 white-bg mb-20">
         <div class="course__video">
            <div class="course__video-thumb w-img mb-25">
               <img src="/img/course/video/course-video.jpg" alt="">
               <div class="course__video-play"> 
                  <button data-bs-toggle="modal" data-bs-target="#introVideoModal" href="https://youtu.be/yJg-Y5byMMw" data-fancybox="" class="play-btn"> <i class="fas fa-play"></i> </button>
               </div>
            </div>
            <div class="course__video-meta mb-25 d-flex align-items-center justify-content-between">
               <div class="course__video-price">
                  <h5>{{newPrice.integer}}.<span>{{newPrice.decimals}} JOD</span> </h5>
                  <h5 v-if="course.discountPercent" class="old-price">{{course.price}}</h5>
               </div>
               <div class="course__video-discount">
                  <span v-if="course.discountPercent">{{course.discountPercent}}% OFF</span>
               </div>
            </div>
            <div class="course__video-content mb-35">
               <ul>
                  <li class="d-flex align-items-center">
                     <div class="course__video-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                           <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                           <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                        </svg>
                     </div>
                     <div class="course__video-info">
                        <h5><span>Instructor :</span> {{course.instructorId.firstName + ' ' + course.instructorId.lastName}}</h5>
                     </div>
                  </li>
                  <li class="d-flex align-items-center">
                     <div class="course__video-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
                           
                           <path class="st0" d="M4,19.5C4,18.1,5.1,17,6.5,17H20"/>
                           <path class="st0" d="M6.5,2H20v20H6.5C5.1,22,4,20.9,4,19.5v-15C4,3.1,5.1,2,6.5,2z"/>
                        </svg>
                     </div>
                     <div class="course__video-info">
                        <h5><span>Lectures :</span>{{course.lessons}}</h5>
                     </div>
                  </li>
                  <li class="d-flex align-items-center">
                     <div class="course__video-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                           <circle class="st0" cx="8" cy="8" r="6.7"/>
                           <polyline class="st0" points="8,4 8,8 10.7,9.3 "/>
                        </svg>
                     </div>
                     <div class="course__video-info">
                        <h5><span>Duration :</span>{{duration}}</h5>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="course__enroll-btn">
               <router-link :to="'/checkout?courses='+course._id" class="e-btn e-btn-7 w-100">Enroll 
                  <i class="far fa-arrow-right"></i>
               </router-link>
               <a href="#" @click.prevent="$root.addToCart(course)" class="e-btn e-btn-border w-100 mt-10">Add to Cart
               </a>
            </div>
         </div>
      </div>

   </div>
</div>

<!-- video modal  start-->
<div>
   <div class="video-modal-wrapper">
      <div class="modal" id="introVideoModal">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-body">
                  <p><iframe :src="course.introVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- video modal  end-->
</template>

<script>

export default {
   name:'courseSidebar',
   props:['course'],
   computed:{
      duration(){
         let hours = 0;
         this.course.content?.forEach(section=>{
            section.videos?.forEach(vid=>{
               hours += vid.duration
            })
         })
         hours /= 3600;
         hours = Math.round(hours * 100) / 100;
         let minutes= (hours - Math.floor(hours)) * 60
         minutes = Math.round(minutes * 100) / 100
         hours = Math.floor(hours)
         return (hours? `${hours} Hours`: '') + ` ${minutes} Minutes`
      },
      newPrice(){
         let p = this.course.price - this.course.price * this.course.discountPercent / 100
         let integer = Math.floor(p) 
         let decimals = p - integer;
         return { integer, decimals };
      }
   },
   components:{
   },
   data(){
      return {
         modalStatus:false
      }
   },

};
</script>

