<template>
 <section class="cart-area pt-100 pb-100">
   <div class="container">
      <div class="row">
         <div class="col-12">
            <div class="table-content table-responsive">
               <table v-if="cartCourses?.length == 0 || (cartCourses && cartCourses[0]?._id)" class="table">
                     <thead>
                        <tr>
                           <th class="product-thumbnail"></th>
                           <th class="cart-product-name">Product</th>
                           <th class="product-price">Price</th>
                           <th class="product-subtotal">Total</th>
                           <th class="product-remove">Remove</th>
                        </tr>
                     </thead>
                     <tbody class="border-0">
                        <tr
                           v-for='(course, i) in cartCourses'
                           :key='i'
                        >
                           <td class="product-thumbnail">
                              <router-link :to="'/courses/'+course._id">
                                 <img :src="$url+'/images/courses/' + course.thumbnailUrl" alt="">
                              </router-link>
                           </td>
                           <td class="product-name">
                              <router-link :to="'/courses/'+course._id">{{course.name}}</router-link>
                           </td>
                           <td class="product-price"><span class="amount">{{course.price}}</span></td>
                           <td class="product-subtotal">
                              <span class="amount">{{course.price - course.price * course.discountPercent / 100}}</span>
                           </td>
                           <td class="product-remove">
                              <a href="#" @click.prevent="$root.removeFromCart(course._id)"><i class="fa fa-times"></i></a>
                           </td>
                        </tr>
                     </tbody>
               </table>
               <div v-else style="height:350px;position:relative">
                  <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
               </div>
            </div>
            <div class="row" v-if="cartCourses?.length">
               <div class="col-md-5 ml-auto">
                     <div class="cart-page-total">
                        <h2>Cart totals</h2>
                        <ul class="mb-20">
                           <li>Total <span>{{cartTotal}}JOD</span></li>
                        </ul>
                        <router-link class="e-btn e-btn-border" :to="checkoutRoute">Proceed to checkout</router-link>
                     </div>
               </div>
            </div>
         </div>
      </div>
   </div>
 </section>
</template>

<script>
export default {
   name:'CartArea', 
   props:['cartCourses'],
   computed:{
      cartTotal(){
         let total = 0;
         this.cartCourses?.forEach(course=>{
            total += course.price - course.price * course.discountPercent / 100
         })
      return total;
      },
      checkoutRoute(){
         let route = { path:'/checkout', query:{}};
         if (this.cartCourses?.length && typeof this.cartCourses[0] === "string"){
            route.query.courses = this.cartCourses
         } else {
            route.query.courses = this.cartCourses.map(c => c._id)
         }
         return route;
      }
   },
   mounted(){
      if (this.cartCourses?.length && typeof this.cartCourses[0] === "string"){
         this.$root.loadCourses();
      }
   }
};
</script>

