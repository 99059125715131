<template>
    <form class="dashboard__form-input mb-30" @submit.prevent="changePassword">
        <input type="password" placeholder="Old Password" v-model="form.oldPassword" required>
        <input type="text" placeholder="New Password" required v-model="form.newPassword">
        <input type="password" placeholder="Confirm New Password" required v-model="confirmPassword">

        <button type="submit" class="e-btn"><span>Save</span></button>
    </form>
</template>

<script>
import api from '../../api/'
export default {
    name:'PasswordChange',
    props:['id'],
    emits:['toast'],
    data(){
        return {
            form:{   
                oldPassword:'',
                newPassword:''
            },
            confirmPassword:''
        }
    },
    methods:{
        changePassword(){
            if (this.form.newPassword == this.confirmPassword){
                fetch(`${api.Url}/api/users/${this.id}/change-password`,{
                    method:"POST",
                    headers: api.headers(),
                    body:JSON.stringify(this.form)
                })
                    .then(res=>res.json())
                    .then(res => {
                        if (res.success){
                            this.$emit('toast', true)
                        } else {
                            this.$emit('toast', false)
                        }
                    })
                    .catch(()=>{
                        this.$emit('toast', false)
                    })
            } else {
                this.confirmPassword = ''
            }
        }
    }
}
</script>

<style>

</style>