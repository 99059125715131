<template>
   <section class="checkout-area pb-70 pt-40">
      <div class="container">
         <div class="row justify-content-center">
            <div class="col-lg-6">
               <h4 style="font-weight:400;text-align:center" class="mb-20">
               <span>Contact Us to pay on ZainCash and get a coupon <br>
                  University Of Jordan: {{$root.about?.phones?.phone1}} <br>
                  Al Balqa'a University: {{$root.about?.phones?.phone2}}
               </span></h4>
               <div class="your-order mb-30 ">
                  <h3>Your order</h3>
                  <div class="your-order-table table-responsive">
                     <table>
                        <thead>
                           <tr>
                              <th class="product-name">Course</th>
                              <th class="product-total">Total</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr
                              class="cart_item"
                              v-for='(course, idx) in courses'
                              :key="idx"
                           >
                              <td class="product-name">
                                 {{course.name}}
                              </td>
                              <td class="product-total">
                                 <span class="amount">{{course.price - course.price * course.discountPercent / 100 }}</span>
                              </td>
                           </tr>
                        </tbody>
                        <tfoot v-if="courses">
                           <tr class="order-total">
                              <th>Order Total</th>
                              <td><strong><span class="amount">{{total}}</span></strong>
                              </td>
                           </tr>
                        </tfoot>
                     </table>
                  </div>
                  <div v-if="loading" style="height:100px;position:relative">
                     <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
                  </div>
                  <div class="coupon-info pt-35">
                     <form @submit.prevent="redeemCoupon" v-if='total>0'>
                           <div class="checkout-coupon">
                              <input type="text" placeholder="Coupon Code" :required="total>0" v-model.trim="fieldCoupon"/>
                              <button v-if="!coupon" class="e-btn e-btn-border" type="submit">Apply Coupon</button>
                           </div>
                     </form>
                     <div class="mb-1 fw-bolder" v-if="coupon">Coupon Applied: {{coupon.codeName}}</div>
                     <button v-if='coupon || total<=0' class="e-btn e-btn-border w-100" @click="buy">Buy</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import api from '@/api';
import Loading from 'vue-loading-overlay';

export default {
   name:'CheckoutArea',
   props:['phone'],
   components:{
      Loading
   },
   data () {
      return {
         courses: null,
         coupon: null,
         fieldCoupon:'',
         loading:false
      }
   },
   computed:{
      total(){
         let total = 0;
         this.courses?.forEach(course=>{
            total += course.price - course.price * course.discountPercent / 100
         })
         if (this.coupon){
            total -= this.coupon?.amount
         }
         total = Math.max(total, 0)
         return total;
      }  
   },
   methods:{
      loadCourses(ids){
         this.loading = true
         fetch(`${api.Url}/api/courses?` + new URLSearchParams({
            ids: ids
         }), {
            method: 'GET',
         })
         .then(res=>res.json())
         .then(res =>{
            this.courses = res.courses
            this.loading = false
         })
      },
      redeemCoupon(){
         this.loading = true
         fetch(`${api.Url}/api/enroll/redeem`, {
            method: 'POST',
            headers: api.headers(),
            body:JSON.stringify({coupon: this.fieldCoupon})
         })
         .then(res=>{
            if(res.status == 401){
               this.$router.push('/login')
            }
            else
               return res.json()
         })
         .then(res =>{
            if(res.success){
               this.coupon = res.coupon;
               this.loading = false
            }         
         })
      },
      buy(){
         this.loading = true
         let ids = this.courses?.map(function(course) {
            return course['_id'];
         });
         fetch(`${api.Url}/api/enroll/buy`, {
            method: 'POST',
            headers: api.headers(),
            body:JSON.stringify({
               coupon: this.fieldCoupon,
               courses: ids
            })
         })
         .then(res=>{
            if(res.status == 401){
               this.$router.push('/login')
            }
            else
               return res.json()
         })
         .then(res =>{
            console.log(res)
            if (res.success){
               this.$router.push({path:'/'})
            }
            this.loading = false
         })
      },
   },
   mounted(){
      let courses = this.$route.query.courses;
      this.loadCourses(courses)
   }
};
</script>
