<template>
   <section class="signup__area po-rel-z1 pt-100 pb-145">
      <div class="toast-container">
         <div class="toast align-items-center text-white bg-primary border-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref='toast'>
            <div class="d-flex">
                  <div class="toast-body">
                     {{ toastError}}
                  </div>
                  <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
         </div>
      </div>
      <div class="sign__shape">
         <img class="man-1" src="/img/icon/sign/man-1.png" alt="">
         <img class="man-2" src="/img/icon/sign/man-2.png" alt="">
         <img class="circle" src="/img/icon/sign/circle.png" alt="">
         <img class="zigzag" src="/img/icon/sign/zigzag.png" alt="">
         <img class="dot" src="/img/icon/sign/dot.png" alt="">
         <img class="bg" src="/img/icon/sign/sign-up.png" alt="">
      </div>
      <div class="container">
         <div class="row">
            <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
               <div class="section__title-wrapper text-center mb-55">
                  <h2 class="section__title">Reset Password</h2>

               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
               <div class="sign__wrapper white-bg">
                  <div v-if="!sent" class="sign__form">
                     <form @submit.prevent="reset">
                        <div v-if="$route.query.token" class="sign__input-wrapper mb-10">
                           <h5>Password</h5>
                           <div class="sign__input mb-25">
                              <input type="password" placeholder="Password" required v-model.trim="password">
                              <i class="fal fa-lock"></i>
                           </div>
                        </div>
                        <div v-else class="sign__input-wrapper mb-25">
                           <h5>Email</h5>
                           <div class="sign__input">
                              <input type="text" placeholder="e-mail address" required v-model.trim="email">
                              <i class="fal fa-envelope"></i>
                           </div>
                        </div>
                        <button type="submit" class="e-btn  w-100"> <span></span>{{$route.query.token ? 'Reset':'Send Email'}}</button>
                     </form>
                  </div>
                  <h4 style="text-align:center" v-else>
                    Please Check Your Inbox
                  </h4>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import {Toast} from 'bootstrap'
import api from '@/api'

export default {
    name:"PasswordReset",
    data(){
        return {
            toastError:'',
            email:"",
            password:"",
            sent:false
        }
    },
    methods:{
       reset(){
           let body = {};
           let token = this.$route.query.token
           if (token){
               body.token = token
               body.newPassword = this.password
           } else {
               body.email = this.email
           }
           console.log(body)
           fetch(`${api.Url}/api/users/reset`,{
               method:'POST',
               headers: api.headers(false, true),
               body:JSON.stringify(body)
           })
           .then(res => res.json())
           .then(res => {
               if (res.success){
                   if (token){
                       this.$router.push({name: "Login"})
                   } else {
                       this.sent = true
                   }
               } else {
                  this.toastError = res.err || "Failed to Reset";
                  new Toast(this.$refs.toast).show({delay:1000})
               }
           })
       }
    }
}
</script>

<style>

</style>