<template>
    <section class="page__title-area pt-120 pb-90">
        <div class="page__title-shape">
            <img class="page-title-shape-5 d-none d-sm-block" src="/img/page-title/page-title-shape-1.png" alt="">
            <img class="page-title-shape-6" src="/img/page-title/page-title-shape-6.png" alt="">
            <img class="page-title-shape-7" src="/img/page-title/page-title-shape-4.png" alt="">
        </div>
        <div class="course__content-display-wrapper">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-lg-8">
                    <div class="course__content-aspect-ratio">
                        <iframe :src="video + '?title=0&portrait=0&byline=0&transparent=0'" width="1000" height="275" frameborder="0"
                            webkitallowfullscreen mozallowfullscreen allowfullscreen
                            controls="0"
                            allow="fullscreen; picture-in-picture"
                            v-if="video"
                        ></iframe>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4">
                    <div class="course__content-scroller">
                        <CurriculumAccordion :content="course.content" @video="(e) => {video = e}"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="course__wrapper">

                <div class="course__tab-2 mb-45">
                    <ul class="nav nav-tabs" id="courseTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="true"> <i class="icon_ribbon_alt"></i> <span>Contact</span> </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false"> <i class="icon_star_alt"></i> <span>Reviews</span> </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="materials-tab" data-bs-toggle="tab" data-bs-target="#materials" type="button" role="tab" aria-controls="materials" aria-selected="false"> <span>Materials</span> </button>
                    </li>
                    </ul>
                </div>
                <div class="course__tab-content mb-95">
                    <div class="tab-content" id="courseTabContent">
                    <div class="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <div class="fw-bolder mb-1" v-if="course.expiresAt">Expires at {{$dateFormat(course.expiresAt)}} ({{Math.ceil((new Date(course.expiresAt) - new Date())/86400000)}} days left)</div>
                            <h4 v-if="course.phone" class="mb-30">{{course.phone}}</h4>
                            <div v-if="course.whatsappGroup" class="mb-20">
                                <h4> <span>Whatsapp Group: </span> <small><i><a href="javascript:void(0);" @click="openUrl(course.whatsappGroup)" style="font-weight: 400">Click Here</a></i></small></h4>
                            </div>
                            <div v-if="course.facebookGroup" class="mb-20">
                                <h4> <span>Facebook Group: </span> <small><i><a href="javascript:void(0);" @click="openUrl(course.facebookGroup)" style="font-weight: 400">Click Here</a></i></small></h4>
                            </div>
                            <div v-if="course.telegramChannel" class="mb-20">
                                <h4> <span>Telegram Group: </span> <small><i><a href="javascript:void(0);" @click="openUrl(course.telegramChannel)" style="font-weight: 400">Click Here</a></i></small></h4>
                            </div>
                            <div v-if="course.OtherResoursesUrl" class="mb-20">
                                <h4> <span>Other Resources(More Materials): </span> <small><i><a href="javascript:void(0);" @click="openUrl(course.OtherResoursesUrl)" style="font-weight: 400">Click Here</a></i></small></h4>
                            </div>
                    </div>

                    <ReviewTab :course="course" :enrolled="true" :reviewed="reviewed"/>

                    <AttachmentsList :attachments="course.attachments"/>

     
                   <ShareButtons/>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
import ReviewTab from './ReviewTab.vue';
import CurriculumAccordion from './CurriculumAccordion.vue'
import AttachmentsList from './AttachmentsList.vue'
import ShareButtons from '../ShareButtons.vue'
import ErrorHandler from '../../api/errorhandler'
export default {
    name:'EnrolledCourse',
    components:{
      ReviewTab,
      CurriculumAccordion,
      AttachmentsList,
      ShareButtons
    },
    props: ['course', 'reviewed'],
    data(){
        return {
            video:''
        }
    },
    methods:{
        openUrl(url){
            if (!url.includes("https://"))
                url = "https://" + url
            window.open(url)
        },
        async checkOtherLogin(){
            let res = await this.$root.loadUser()
            if (res.user)
                return;
            else
                ErrorHandler.unauthenticatedWhileWatching.bind(this)()
        }
    },
    mounted(){
        this.interval = setInterval(this.checkOtherLogin, 1000 * 60 * 0.5) 
    },
    beforeUnmount(){
        clearInterval(this.interval);
    }
}
</script>

<style>

</style>