<template>
 <footer>
   <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <div class="footer__logo">
                           <router-link to="/">
                              <img height="85" src="/img/logo/logo.png" alt="">
                           </router-link>
                        </div>
                     </div>
                     <div :class="`${footerBgTwo ? 'footer__widget-body-2' : 'footer__widget-body'}`">
                        <p>Great lesson ideas and lesson plans for ESL teachers! Educators can customize lesson plans to best.</p>

                        <div class="footer__social">
                           <ul>
                              <li v-if="social?.facebook"><a :href="social.facebook"><i class="fab fa-facebook-f"></i></a></li>
                              <li v-if="social?.twitter"><a :href="social.twitter" class="tw"><i class="fab fa-twitter"></i></a></li>
                              <li v-if="social?.linkedIn"><a :href="social.linkedIn" class="tw"><i class="fab fa-linkedin"></i></a></li>
                              <li v-if="social?.instagram"><a :href="social.instagram" class="pin"><i class="fab fa-instagram"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Links</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><router-link to="/">Home</router-link></li>
                              <li><router-link to="/courses">Courses</router-link></li>
                              <li><router-link to="/events">Events</router-link></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">
                           Contact Us: {{phones?.phone1}}
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div :class="`${footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'}`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div :class="`${footerBgTwo ? 'footer__copyright-2 text-center' : 'footer__copyright text-center'}`">
                     <p>© 2022 Socrates Academy, All Rights Reserved.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
   name:'FooterArea',
   props:{
      footerBgTwo:String,
      footerPadding:String,
      social:Object,
      phones:Object
   }
};
</script>
