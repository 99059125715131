<template>
   <div v-if="course">
      <EnrolledCourse v-if="enrolled" :course='course' :reviewed='reviewed'/>
      <section v-else class="page__title-area pt-120 pb-90">
      <div class="page__title-shape">
         <img class="page-title-shape-5 d-none d-sm-block" src="/img/page-title/page-title-shape-1.png" alt="">
         <img class="page-title-shape-6" src="/img/page-title/page-title-shape-6.png" alt="">
         <img class="page-title-shape-7" src="/img/page-title/page-title-shape-4.png" alt="">
      </div>
      <div class="container">
         <div class="row">
            <div class="col-xxl-8 col-xl-8 col-lg-8">
               <div class="course__wrapper">
                  <div class="page__title-content mb-25">
                     <div class="page__title-breadcrumb">                            
                           <nav aria-label="breadcrumb">
                              <ol class="breadcrumb">
                                 <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                                 <li class="breadcrumb-item"><router-link to="/courses">Courses</router-link></li>
                                 <li class="breadcrumb-item active" aria-current="page">{{course.name}}</li>
                              </ol>
                           </nav>
                     </div>
                     <span v-if="course.label" class="page__title-pre">{{course.label}}</span>
                     <h5 class="page__title-3">{{course.name}}</h5>
                  </div>
                  <div class="course__meta-2 d-sm-flex mb-30">
                     <div class="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                        <div class="course__teacher-thumb-3 mr-15">
                           <img :src="$url+'/images/users/thumb/'+course.instructorId.profilePictureUrl" alt="">
                        </div>
                        <div class="course__teacher-info-3">
                           <h5>Teacher</h5>
                           <p><a href="#">{{course.instructorId.firstName + ' ' + course.instructorId.lastName}}</a></p>
                        </div>
                     </div>
                     <div class="course__update mr-80 mb-30">
                        <h5>Create At:</h5>
                        <p>{{$dateFormat(course.createdAt)}}</p>
                     </div>
                     <div class="course__rating-2 mb-30">
                        <h5>Review:</h5>
                        <div class="course__rating-inner d-flex align-items-center">
                           <ul>
                              <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                              <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                              <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                              <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                              <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           </ul>
                           <p>{{  (Math.round((course.rating / course.numberOfRaters) * 100 ) /100) || 0}}</p>
                        </div>
                     </div>
                  </div>
                  <div class="course__img w-img mb-30">
                     <img :src="$url+'/images/courses/'+course.thumbnailUrl" alt="">
                  </div>
                  <div class="course__tab-2 mb-45">
                     <ul class="nav nav-tabs" id="courseTab" role="tablist">
                        <li class="nav-item" role="presentation">
                           <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true"> <i class="icon_ribbon_alt"></i> <span>Description</span> </button>
                        </li>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i class="icon_book_alt"></i> <span>Syllabus</span> </button>
                        </li>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false"> <i class="icon_star_alt"></i> <span>Reviews</span> </button>
                        </li>
                     </ul>
                  </div>
                  <div class="course__tab-content mb-95">
                     <div class="tab-content" id="courseTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                           <div class="course__description" v-if="course.description">
                              <h3>Course Overview</h3>
                              <p
                                 v-for="(para,idx) in course.description.split('\n')"
                                 :key='idx'
                                 class="mb-0"
                              >{{para}}</p>

                              <div v-if="course.targetAudience" class="course__description-list mb-45">
                                 <h4>What is the Target Audience?</h4>
                                 <ul>
                                    <li 
                                       v-for="(target,idx) in course.targetAudience.split(',')"
                                       :key="idx"
                                       > 
                                       <i class="fal fa-check"></i> 
                                       {{target}}
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                        <!-- CurriculumTab start -->
                        <CurriculumTab :content="course.content"/>
                        <!-- CurriculumTab end -->

                        <!-- CurriculumTab start -->
                        <ReviewTab :enrolled="false" :course="course"/>
                        <!-- CurriculumTab end -->

                        <ShareButtons/>
                     </div>
                  </div>
               </div>
            </div>

         <!-- course side bar start -->
         <CourseSidebar :course='course'/>
         <!-- course side bar end -->

         </div>
      </div>
      </section>
   </div>
   <div v-else style="height:600px;position:relative">
      <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
   </div>
</template>

<script>
import EnrolledCourse from './EnrolledCourse.vue';
import CourseSidebar from './CourseSidebar.vue';
import CurriculumTab from './CurriculumTab.vue';
import ReviewTab from './ReviewTab.vue';
import api from '@/api'
import Loading from 'vue-loading-overlay';
import ShareButtons from '../ShareButtons.vue'

export default {
   name:'CourseDetailsArea',
   components:{
      CourseSidebar,
      CurriculumTab,
      ReviewTab,
      Loading,
      ShareButtons,
      EnrolledCourse
   },
   data(){
      return {
         course: null
      }
   },
   methods:{
      loadCourse(){
         console.log(this.$route.params.id)
         fetch(`${api.Url}/api/courses/${this.$route.params.id}?populate=true`,{
            method:'GET',
            headers: api.headers()
         })
         .then(res => res.json())
         .then(res => {
            console.log(res)
            if (res.success){
               this.course = res.course
               this.course.attachments?.sort((a,b) => (a.name).localeCompare(b.name))
               this.reviewed = res.reviewed
               this.enrolled = res.enrolled   
            }
         })
         .catch(err => {
            throw err
         })
      }
   },
   created(){
      this.loadCourse()
   }
};
</script>

