<template>
  <div class="row">
    <div class="col-xxl-12">
        <div class="basic-pagination mt-30">
            <div v-if="pages==0" style="text-align:center" class="mt-100">
                Not Found
            </div>
            <ul v-else class="d-flex align-items-center justify-content-center"> 
                <li class="prev">
                    <button @click.prevent="()=>{ if(page!=0){$emit('page', page-1)} }" class="link-btn link-prev">
                        Prev
                        <i class="fas fa-arrow-left"></i>
                        <i class="fas fa-arrow-left"></i>
                    </button>
                </li>
                <li
                    v-for="(n,index) in pages"
                    :key="index"
                    :class="{'active': index == page}"
                >
                    <button @click="$emit('page', index)" class="mb-5">
                        <span>{{index+1}}</span>
                    </button>
                </li>
                <li class="next">
                    <button @click.prevent="()=>{ if(page!=pages-1){$emit('page', page+1)} }" class="link-btn">
                        Next
                        <i class="fas fa-arrow-right"></i>
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:'PaginationArea',
    emits:['page'],
    props:['pages', 'page']
}
</script>

