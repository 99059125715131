<template>
  <Breadcrumb title="My Cart" subtitle="Cart" />
  <CartArea :cartCourses='cartCourses'/>
</template>

<script>
import Breadcrumb from '../components/common/Breadcrumb.vue';
import CartArea from '../components/Cart/CartArea.vue';

export default {
    name:'CartPage',
    props:['cartCourses'],
    components:{
        Breadcrumb,
        CartArea
    }
}
</script>

