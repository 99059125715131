<template>
    <EventDetailsTitle v-if="event" :event="event"/>
    <section v-if="event" class="event__area pb-110">
        <div class="container">
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-lg-8">
                    <div class="events__wrapper">
                        <div class="events__thumb mb-35 w-img">
                            <img v-if='event.pictureUrl' :src="'/images/events/'+event.pictureUrl" alt="">
                        </div>
                        <div class="events__details mb-35">
                            <h3>Description</h3>
                            <p
                                v-for="(para, idx) in event.description.split('\n')"
                                :key="idx"
                                dir="auto"
                            >{{para}}</p>
                        </div>
                        <div v-if="event.aimOfTheEvent" class="events__allow mb-40">
                            <h3>This event will allow participants to:</h3>
                            <ul>
                                <li
                                    v-for="(para, idx) in event.aimOfTheEvent.split(',')"
                                    :key="idx"
                                ><i class="fal fa-check"></i>{{para}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4">
                    <div class="events__sidebar pl-70">
                    <div class="events__sidebar-widget white-bg mb-20">
                        <div class="events__sidebar-shape">
                            <img class="events-sidebar-img-2" src="/img/events/event-shape-2.png" alt="">
                            <img class="events-sidebar-img-3" src="/img/events/event-shape-3.png" alt="">
                        </div>
                        <div class="events__info">
                            <div class="events__info-meta mb-25 d-flex align-items-center justify-content-between">
                                <div class="events__info-price">
                                <h5>{{Math.floor(event.price)}}.<span>{{event.price - Math.floor(event.price)}}JOD</span> </h5>
                                </div>
                            </div>
                            <div class="events__info-content mb-35">
                                <ul>
                                <li class="d-flex align-items-center">
                                    <div class="events__info-icon">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                            <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                            <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                        </svg>
                                    </div>
                                    <div class="events__info-item">
                                        <h5><span>Date: </span> {{new Date(event.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</h5>
                                    </div>
                                </li>
                                <li class="d-flex align-items-center">
                                    <div class="events__info-icon">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                            <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                            <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                        </svg>
                                    </div>
                                    <div class="events__info-item">
                                        <h5><span>Time:</span>{{event.time}}</h5>
                                    </div>
                                </li>
                                <li class="d-flex align-items-center">
                                    <div class="events__info-icon">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                            <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                            <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                        </svg>
                                    </div>
                                    <div class="events__info-item">
                                        <h5><span>Location: </span> {{event.location}}</h5>
                                    </div>
                                </li>
                                </ul>
                            </div>
                            <div class="events__join-btn">
                                <a href="#" @click.prevent="join" class="e-btn e-btn-7 w-100">{{ !joined ? "Join": "Already Joined"}}<i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="events__sidebar-widget white-bg">
                        <div class="events__sponsor">
                            <h3 v-if="event.sponsor" class="events__sponsor-title">Sponsors</h3>
                            <div v-if="event.sponsor" class="events__sponsor-thumb mb-35">
                                <div>{{event.sponsor}}</div>
                            </div>
                            <div class="events__sponsor-info">
                                <h4>Contact: <span>{{event.contact}}</span></h4>
                                <ShareButton/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-else style="height:650px;position:relative">
        <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
    </div>
</template>

<script>
import ShareButton from '../ShareButtons.vue'
import EventDetailsTitle from './EventDetailsTitle.vue';
import Loading from 'vue-loading-overlay';

import api from '@/api'

export default {
    name:'EventDetailsArea',
    components:{
        EventDetailsTitle,
        Loading,
        ShareButton
    },
    data(){
        return {
            event:null,
            joined:false
        }
    },
    methods:{
        loadEvent(){
            fetch(`${api.Url}/api/events/${this.$route.params.id}`,{
                method:'GET',
                headers: api.headers()
            })
            .then(res => res.json())
            .then(res => {
                if (res.success && res.event){
                    this.event = res.event;
                    this.joined = res.joined
                } else {
                    this.$router.replace({name: 'NotFound'})
                }
        })
            .catch(err => {
                throw err
            })
        },
        join(){
            fetch(`${api.Url}/api/events/${this.$route.params.id}/join`,{
                method:'POST',
                headers: api.headers(),
                body:JSON.stringify({join: !this.joined})
            })
            .then(res=>{
                if(res.status == 401){
                    this.$router.push('/login')
                }
                else
                    return res.json()
            })
            .then(res => {
                if (res.success){
                    this.joined = !this.joined;
                }
            })
        }
    },
    mounted(){
        this.loadEvent()
    }
}
</script>

