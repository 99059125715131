import { createWebHistory,createRouter } from "vue-router";
import Home from '../pages/Home.vue';
import CourseSidebar from '../pages/CourseSidebar.vue';
import CourseDetails from '../components/CourseDetails/CourseDetailsArea.vue';
//import About from '../pages/About.vue';
import Instructor from '../pages/Instructor.vue';
import InstructorDetails from '../pages/InstructorDetails.vue';
import EventDetails from '../pages/EventDetails.vue';
import Cart from '../pages/Cart.vue';
import Checkout from '../pages/Checkout.vue';
import SignIn from '../pages/SignIn.vue';
import SignUp from '../pages/SignUp.vue';
import ErrorPage from '../pages/ErrorPage.vue';
//import Contact from '../pages/Contact.vue';
import Dashboard from '../pages/Dashboard';
import Events from '../components/Home/Events';
import Reset from '../pages/Reset.vue'
const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path:'/',
            name:"Home",
            component:Home,
        },
        {
            name:'Search',
            path:'/courses',
            component:CourseSidebar,
        },
        {
            path:'/courses/:id',
            component:CourseDetails,
        },
        {
            path:'/instructor',
            component:Instructor,
        },
        {
            path:'/instructor-details/:id',
            component:InstructorDetails,
        },
        {
            path:'/events/:id',
            component:EventDetails,
        },
        {
            path:'/events',
            component:Events,
        },
        {
            name:'Cart',
            path:'/cart',
            component:Cart,
        },
        {
            path:'/checkout',
            component:Checkout,
        },
        {
            name:"Login",
            path:'/login',
            component:SignIn,
        },
        {
            name:"SignUp",
            path:'/register',
            component:SignUp,
        },
        {   
            name:'Reset',
            path:'/reset',
            component: Reset,
        },
        {
            name:'Dashboard',
            path:'/dashboard',
            component:Dashboard,
        },
        {
            name: 'NotFound',
            path:'/:pathMatch(.*)*',
            redirect: '/404'
        },        
        {
            path:'/404',
            component:ErrorPage,
        },
    ]
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router