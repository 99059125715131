<template>
    <div class="course__curriculum">
        <div 
            v-for="(section, i) in content"
            :key='i'
            class="accordion" 
            :id="'course__accordion-' + i">
            <div class="accordion-item mb-10">
                <h2 class="accordion-header" :id="'section-' + i">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#section-${i}-content`" aria-expanded="true" :aria-controls="`section-${i}-content`">
                        {{section.name}}
                    </button>
                </h2>
                <div :id="`section-${i}-content`" class="accordion-collapse collapse show" :aria-labelledby="'section-' + i" :data-bs-parent="'#course__accordion-' + i">
                    <div 
                        v-for="(video,j) in section.videos"
                        :key="'vid'+i+'-'+j"
                        class="accordion-body">
                        <div @click="playVideo(video.url,i,j)" :class="{'active': (active.i==i && active.j==j)}" class="course__curriculum-content d-flex justify-content-between align-items-center">
                            <div class="course__curriculum-info">
                                <svg viewBox="0 0 24 24">
                                    <polygon class="st0" points="23,7 16,12 23,17 "/>
                                    <path class="st0" d="M3,5h11c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V7C1,5.9,1.9,5,3,5z"/>
                                </svg>
                                <h3> <span> {{video.name}} </span></h3>
                            </div>
                            <div class="course__curriculum-meta">
                                <span class="time">{{ video.duration? (Math.floor(video.duration/60) + ":" + video.duration%60) +' minutes' : 0}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"CurriculumAccordion",
    props:['content'],
    emits:['video'],
    methods:{
        playVideo(url, i, j){
            if(url){
                let vidId = url.split('/');
                vidId = vidId[vidId.length - 1];
                let playerVid = "https://player.vimeo.com/video/" + vidId
                this.$emit('video', playerVid);
                this.active = {i:i, j:j}
            }
        }
    },
    data(){
        return {
            active: {
                i:-1,
                j:-1
            }
        }
    }
}
</script>

<style>

</style>