<template>
  <Breadcrumb title="Checkout" subtitle="Checkout" />
  <CheckoutArea/>
</template>

<script>
import Breadcrumb from '../components/common/Breadcrumb.vue';
import CheckoutArea from '../components/Checkout/CheckoutArea.vue';

export default {
    name:'CheckoutPage',
    components : {
        Breadcrumb,
        CheckoutArea,
    }
}
</script>
