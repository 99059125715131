<template>
 <section class="page__title-area pt-120">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="/img/page-title/page-title-shape-2.png" alt="">
      <img class="page-title-shape-7" src="/img/page-title/page-title-shape-4.png" alt="">
      <img class="page-title-shape-8" src="/img/page-title/page-title-shape-5.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-9 col-xl-8">
            <div class="page__title-content mb-25 pr-40">
               <div class="page__title-breadcrumb">                            
                     <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item"><router-link to="/events">Event</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">{{event.name}}</li>
                     </ol>
                     </nav>
               </div>
               <span v-if="event.label" class="page__title-pre purple-bg">{{event.label}}</span>
               <h5 class="page__title-3">{{event.name}}</h5>
            </div>
            <div class="course__meta-2 d-sm-flex mb-30">
               <div class="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                  <div class="course__teacher-info-3">
                     <h5>Teacher</h5>
                     <p>{{event.teacher}}</p>
                  </div>
               </div>
               <div class="course__update mr-80 mb-30">
                  <h5>Created At:</h5>
                  <p>{{new Date(event.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</p>
               </div>
               <div class="course__update mb-30">
                  <h5>Location:</h5>
                  <p>{{event.location}}</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
   name:'EventDetailsTitle',
   props:['event'],
};
</script>
