<template>
  <EventDetailsArea/>
</template>

<script>
import EventDetailsArea from '../components/EventDetails/EventDetailsArea.vue';

export default {
    name:'EventDetails',
    components:{
        EventDetailsArea,
    }
}
</script>

