<template>
   <section v-if="events" class="events__area pt-115 pb-120 p-relative">
      <div class="events__shape">
         <img class="events-1-shape" src="/img/events/events-shape.png" alt="">
      </div>
      <div class="container">
         <div class="row">
            <div class="col-xxl-4 offset-xxl-4">
               <div class="section__title-wrapper mb-60 text-center">
                  <h2 class="section__title"><span class="yellow-bg yellow-bg-big">Events<img src="/img/shape/yellow-bg.png" alt=""></span></h2>
                  <p>We have Found {{total}} events</p>
               </div>
            </div>
         </div>
         <div class="row">
            <div v-for="event in events" :key="event.id" class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="events__item mb-10 hover__active">
                  <div class="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                     <div class="events__content">
                        <div class="events__meta">
                           <span>{{$dateFormat(event.date)}}</span>
                           <span>{{event.time}}</span>
                           <span>{{event.location}}</span>
                        </div>
                        <h3 class="events__title">
                           <router-link :to="'/events/'+event._id">{{event.name}}</router-link>
                        </h3>
                     </div>
                     <div class="events__more">
                        <router-link :to="'/events/'+event._id" class="link-btn">
                           View More
                           <i class="far fa-arrow-right"></i>
                           <i class="far fa-arrow-right"></i>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="home" class="d-flex justify-content-end mt-50">
            <router-link to="/events" class="link-btn">
               View all Events
               <i class="far fa-arrow-right"></i>
               <i class="far fa-arrow-right"></i>
            </router-link>
         </div>
      </div>
      <Pagination v-if="!home" :pages="(Math.ceil(this.total/10))" :page="page" @page="changePage"/>
   </section>
   <div v-else style="height:200px;position:relative">
      <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
   </div>
</template>

<script>
import Pagination from '../common/Pagination.vue';

import Loading from 'vue-loading-overlay';
import api from '@/api'

export default {
   name:'EventArea',
   components:{Loading,Pagination},
   props:['home'],
   data () {
      return {
         events: [],
         total:0,         
         page:0,
      }
   },
   methods: {
      changePage(page){
         this.page = page;
         this.loadEvents();
      },
      loadEvents(){
         fetch(`${api.Url}/api/events?page=${this.page}&perPage=${(this.home?5:10)}`)
            .then(res => res.json())
            .then(res => {
               if (res.success){
                  this.events = res.events
                  this.total = res.total
               }
            })
            .catch(err => {
               throw err
            })
      }
   },
   mounted(){
      this.loadEvents()
   }
};
</script>
