<template>
   <section v-if="user" class="teacher__area pt-100 pb-110">
      <div class="page__title-shape">
         <img class="page-title-shape-5 d-none d-sm-block" src="/img/page-title/page-title-shape-1.png" alt="">
         <img class="page-title-shape-6" src="/img/page-title/page-title-shape-6.png" alt="">
         <img class="page-title-shape-3" src="/img/page-title/page-title-shape-3.png" alt="">
         <img class="page-title-shape-7" src="/img/page-title/page-title-shape-4.png" alt="">
      </div>
      <div class="container">
         <div class="row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
               <div class="teacher__details-thumb p-relative w-img pr-30">
                  <img :src="$url+'/images/users/'+user.profilePictureUrl" alt="">
                  <div class="teacher__details-shape">
                     <img class="teacher-details-shape-1" src="/img/teacher/details/shape/shape-1.png" alt="">
                     <img class="teacher-details-shape-2" src="/img/teacher/details/shape/shape-2.png" alt="">
                  </div>
               </div>
            </div>
            <div class="col-xxl-8 col-xl-8 col-lg-8">
               <div class="teacher__wrapper">
                  <div class="teacher__top d-md-flex align-items-end justify-content-between">
                     <div class="teacher__info">
                        <h4>{{user.firstName + ' ' + user.lastName}}</h4>
                     </div>
                     <div class="teacher__social-2">
                        <h4>Contact:</h4>
                        <ul>
                           <li v-if='user.facebook'>
                              <a :href="user.facebook">
                                 <i class="fab fa-facebook-f"></i>
                              </a>
                           </li>
                           <li v-if='user.instagram'>
                              <a :href="user.instagram">
                                 <i class="fab fa-instagram ms-2"></i>
                              </a>
                           </li>
                           <li v-if='user.whatsapp'>
                              <a :href="user.whatsapp">
                                 <i class="fab fa-whatsapp ms-2"></i>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="teacher__courses pt-55">
                     <div class="section__title-wrapper mb-30">
                        <h2 class="section__title">Teacher Top <span class="yellow-bg yellow-bg-big">Courses<img src="/img/shape/yellow-bg.png" alt=""></span></h2>
                     </div>
                     <div class="teacher__course-wrapper">
                        <CoursesCards :courses="courses"/>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <div v-else style="height:650px;position:relative">
        <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
    </div>
</template>

<script>
import api from '@/api'
import Loading from 'vue-loading-overlay';
import CoursesCards from '../../components/CourseSidebar/CoursesCards.vue'
export default {
   name:'InstructorDetailsArea',
   components:{
      Loading,
      CoursesCards
   },
   data(){
      return {
         user: null,
         courses: null
      }
   },
   methods: {
      async loadCourses(){
         let res = await fetch(`${api.Url}/api/courses?instructor=${this.$route.params.id}`);
         return await res.json();
      },
      async loadUser(){
         let res = await fetch(`${api.Url}/api/users/${this.$route.params.id}`);
         return await res.json();
      }
   },
   mounted(){
      Promise.all([
         this.loadUser(),
         this.loadCourses()
      ])
      .then((value)=>{
         console.log(value)
         if (!value[0].user){
            this.$router.push({path:'/404'});
         }
         this.user = value[0].user
         this.courses = value[1].courses
       })
   }
};
</script>

