<template>
   <div class="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
      <CurriculumAccordion :content="content"/>
   </div>
</template>

<script>
import CurriculumAccordion from './CurriculumAccordion.vue'
export default {
  name: "CurriculumTab",
  components:{
   CurriculumAccordion
  },
  props: ['content']
};
</script>
