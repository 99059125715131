export default {
    data() {
        return {
           colors:  [
                '',
                'sky-blue',
                'green',
                'blue',
                'orange',
                'pink',
                'blue-2',
           ]
        }
    }
}