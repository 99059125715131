<template>
  <HeaderAnnouncement v-if="announcement?.english && announcement?.url" :announcement="announcement"/>
  <div style="position:relative">
    <header>
      <div id="header-sticky"
        :class="`${isSticky ? 'header__area header__transparent header__padding sticky'
        :'header__area header__transparent header__padding'} ${header__white && header__white}`"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
              <div class="header__left d-flex align-items-center">
                <div class="logo">
                  <router-link to="/">
                    <img src="/img/logo/logo.png" alt="logo" height="50" />
                  </router-link>
                </div>
                <div class="header__category d-none d-lg-block">
                  <nav>
                    <ul>
                      <li>
                        <router-link
                          to="/courses"
                          class="cat-menu d-flex align-items-center"
                        >
                          <div class="cat-dot-icon d-inline-block">
                            <svg viewBox="0 0 276.2 276.2">
                              <g>
                                <g>
                                  <path
                                    class="cat-dot"
                                    d="M33.1,2.5C15.3,2.5,0.9,17,0.9,34.8s14.5,32.3,32.3,32.3s32.3-14.5,32.3-32.3S51,2.5,33.1,2.5z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M137.7,2.5c-17.8,0-32.3,14.5-32.3,32.3s14.5,32.3,32.3,32.3c17.8,0,32.3-14.5,32.3-32.3S155.5,2.5,137.7,2.5    z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M243.9,67.1c17.8,0,32.3-14.5,32.3-32.3S261.7,2.5,243.9,2.5S211.6,17,211.6,34.8S226.1,67.1,243.9,67.1z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M32.3,170.5c17.8,0,32.3-14.5,32.3-32.3c0-17.8-14.5-32.3-32.3-32.3S0,120.4,0,138.2S14.5,170.5,32.3,170.5z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M136.8,170.5c17.8,0,32.3-14.5,32.3-32.3c0-17.8-14.5-32.3-32.3-32.3c-17.8,0-32.3,14.5-32.3,32.3    C104.5,156.1,119,170.5,136.8,170.5z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M243,170.5c17.8,0,32.3-14.5,32.3-32.3c0-17.8-14.5-32.3-32.3-32.3s-32.3,14.5-32.3,32.3    C210.7,156.1,225.2,170.5,243,170.5z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M33,209.1c-17.8,0-32.3,14.5-32.3,32.3c0,17.8,14.5,32.3,32.3,32.3s32.3-14.5,32.3-32.3S50.8,209.1,33,209.1z    "
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M137.6,209.1c-17.8,0-32.3,14.5-32.3,32.3c0,17.8,14.5,32.3,32.3,32.3c17.8,0,32.3-14.5,32.3-32.3    S155.4,209.1,137.6,209.1z"
                                  />
                                  <path
                                    class="cat-dot"
                                    d="M243.8,209.1c-17.8,0-32.3,14.5-32.3,32.3c0,17.8,14.5,32.3,32.3,32.3c17.8,0,32.3-14.5,32.3-32.3    S261.6,209.1,243.8,209.1z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <span>Category</span>
                        </router-link>
                        <ul class="cat-submenu">
                          <li v-for="(cat, idx) in categories"
                              :key="idx"
                          >
                            <router-link :to="'/courses?categories='+cat.id">{{cat.name}}</router-link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
              <div
                class="
                  header__right
                  d-flex
                  justify-content-end
                  align-items-center
                "
              >
                <div class="header__search p-relative ml-50 d-none d-md-block">
                <form @submit.prevent="search">
                    <input name="name" type="text" placeholder="Search...">
                    <button type="submit"><i class="fad fa-search"></i></button>
                </form>
                  <div class="header__cart">
                    <a @click="handleMiniCart" href="javascript:void(0);" class="cart-toggle-btn">
                      <div class="header__cart-icon">
                        <svg viewBox="0 0 24 24">
                          <circle class="st0" cx="9" cy="21" r="1" />
                          <circle class="st0" cx="20" cy="21" r="1" />
                          <path
                            class="st0"
                            d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"
                          />
                        </svg>
                      </div>
                      <span class="cart-item">{{cartCourses?.length || 0}}</span>
                    </a>
                  </div>
                </div>
                <div class="header__btn ml-20 d-none d-sm-block">
                  <div v-if="user" class="header__user-menu">
                    <router-link to="/dashboard">
                      <img 
                        style="border-raduis:25px; width:32px;height:32px;object-fit:cover" 
                        :src="$url+'/images/users/thumb/'+ user.profilePictureUrl"
                        @error="$userImageErrorHandler"
                      >
                      {{user.firstName + ' ' + user.lastName}}
                    </router-link>

                    <div class="submenu">
                      <ul>
                        <li>                
                          <router-link to="/dashboard">Settings</router-link>
                        </li>
                        <li>
                          <router-link to="/" @click.prevent="$emit('logout')" class="mt-5">Logout</router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <router-link v-if='!user' to="/register" class="e-btn mr-5">Register</router-link>
                  <router-link v-if='!user' to="/login" class="e-btn">Login</router-link>
                </div>
                <div class="sidebar__menu d-xl-none">
                  <div @click="handleSidebar" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- cart mini area start -->
    <div class="cartmini__area">
        <div :class="`${showMiniCart ? 'cartmini__wrapper opened' : 'cartmini__wrapper'}`">
          <div class="cartmini__title">
              <h4>Shopping cart</h4>
          </div>
          <div @click="handleCartClose" class="cartmini__close">
              <button type="button" class="cartmini__close-btn">
                <i class="fal fa-times"></i>
              </button>
          </div>
          <div class="cartmini__widget">
              <div class="cartmini__inner">
                <ul v-if="cartCourses?.length == 0 || (cartCourses && cartCourses[0]?._id)">
                    <li
                      v-for="(course, idx) in cartCourses"
                      :key="idx"
                    >
                      <div class="cartmini__thumb">
                          <a href="#">
                            <img :src="$url+'/images/courses/' + course.thumbnailUrl" alt="">
                          </a>
                      </div>
                      <div class="cartmini__content">
                          <h5><a href="#">{{course.name}}</a></h5>
                          <div class="product__sm-price-wrapper">
                            <span class="product__sm-price">{{course.price - course.price * course.discountPercent / 100}}</span>
                          </div>
                      </div>
                      <a href="#" class="cartmini__del" @click.prevent="$root.removeFromCart(course._id)"><i class="fal fa-times"></i></a>
                    </li>
                </ul>
                <div v-else style="height:350px;position:relative">
                  <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
                </div>
              </div>
              <div class="cartmini__checkout">
                <div class="cartmini__checkout-title mb-30">
                    <h4>Total:</h4>
                    <span>{{cartTotal}}</span>
                </div>
                <div class="cartmini__checkout-btn">
                    <router-link to="/cart" class="e-btn e-btn-border mb-10 w-100"> <span></span>Cart</router-link>
                    <router-link v-if="cartCourses?.length" :to="checkoutRoute" class="e-btn w-100"> <span></span>Checkout</router-link>
                </div>
              </div>
          </div>
        </div>
    </div>

    <div @click="handleCartClose" :class="[showMiniCart ? 'body-overlay opened' : 'body-overlay']"></div>
    <!-- cart mini area end -->

    <!-- sidebar area start -->
    <div :class="`${showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`">
        <div class="sidebar__wrapper">
          <div @click="handleSidebarClose" class="sidebar__close">
              <button class="sidebar__close-btn" id="sidebar__close-btn">
              <span><i class="fal fa-times"></i></span>
              <span>close</span>
              </button>
          </div>
          <div class="sidebar__content">
              <div class="logo mb-40">
                <router-link to="/">
                  <img src="/img/logo/logo.png" alt="logo" width="150">
                </router-link>
              </div>

              <div class="side-info-content sidebar-menu mm-menu">
                    
                  <ul>
                    <li>
                        <router-link to="/" >Home</router-link>
                    </li>
                    <li>
                        <router-link to="/courses" >Courses</router-link>
                    </li>
                    <li>
                        <router-link to="/events">Events</router-link>
                    </li>
                    <li v-if="user">
                      <router-link to="/dashboard">
                        My Account
                      </router-link>
                      <router-link to="/dashboard?startingTab=2">
                        My Courses
                      </router-link>
                    </li>
                    <li v-if="user">
                      <router-link to="/" @click.prevent="$emit('logout')" class="mt-5">Logout</router-link>
                    </li>
                    <li v-if="!user">
                      <router-link to="/login">Login</router-link>
                    </li>
                    <li v-if="!user">
                        <router-link to="/register">Register</router-link>
                    </li>
                </ul>

              </div>
              <div class="mt-20">
                For More Info: <b>{{phones?.phone1}}</b>
              </div>

              <div class="sidebar__search p-relative mt-40 ">
                <form @submit.prevent="search">
                    <input name="name" type="text" placeholder="Search...">
                    <button type="submit"><i class="fad fa-search"></i></button>
                </form>
              </div>
              <div class="sidebar__cart mt-30">
                <a @click="handleMiniCart" href="javascript:void(0);">
                    <div class="header__cart-icon">
                      <svg viewBox="0 0 24 24">
                          <circle class="st0" cx="9" cy="21" r="1"/>
                          <circle class="st0" cx="20" cy="21" r="1"/>
                          <path class="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
                      </svg>
                    </div>
                    <span class="cart-item">{{cartCourses?.length || 0}}</span>
                </a>
              </div>
          </div>
        </div>
    </div> 
    <div @click="handleSidebarClose" :class="[showSidebar ? 'body-overlay opened' : 'body-overlay']"></div>
    <!-- sidebar area end -->

  </div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import HeaderAnnouncement from '../../components/Home/Announcement.vue'

export default {
  name: "HomeHeader",
  components:{
    Loading,
    HeaderAnnouncement
  },
  props:{
    categories:Array,
    header__white:String,
    user: Object,
    announcement: Object,
    cartCourses: Array,
    phones: Object
  },
  emits:['logout', 'loadCart'],
  computed:{
    cartTotal(){
      let total = 0;
      this.cartCourses?.forEach(course=>{
        total += course.price - course.price * course.discountPercent / 100
      })
      return total;
    },
    checkoutRoute(){
        let route = { path:'/checkout', query:{}};
        if (this.cartCourses?.length && typeof this.cartCourses[0] === "string"){
          route.query.courses = this.cartCourses
        } else {
          route.query.courses = this.cartCourses.map(c => c._id)
        }
        return route;
    }
  },
  data () {
    return {
      isSticky:false,
      showMiniCart:false,
      showSidebar:false,
      value:1,
      valueTwo:1,
      valueThree:1,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        coursesDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
    }
  },
  methods:{
      search(e){        
        this.$router.push({name:"Search", query:{name: e.target.elements.name.value}})
      },
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       handleMiniCart () {
          if (this.cartCourses && typeof this.cartCourses[0] === "string"){
            this.$emit('loadCart');
          }
          this.showMiniCart = true;
       },
       handleCartClose () {
         this.showMiniCart = false;
       },
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
         this.showSidebar = false;
       },
  },
  mounted() {
    window.addEventListener('scroll',this.handleSticky)
   },
};
</script>