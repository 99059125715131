<template>
   <section class="page__title-area page__title-height d-flex align-items-center">
      <div class="container">
         <div class="row">
            <div class="col-xxl-12">
               <div class="page__title-wrapper mt-110">
                  <h3 class="page__title">{{title}}</h3>                         
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                           <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                        {{subtitle}}</li>
                     </ol>
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'PageTitle',
   props:{
      title:String,
      subtitle:String,
   }
};
</script>

