<template>
 <section class="course__area pt-120 pb-120">
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="course__tab-inner grey-bg-2 mb-50 d-sm-flex justify-content-between align-items-center">
               <div class="course__tab-wrapper d-flex align-items-center">
                  <div class="course__tab-btn">
                     <ul class="nav nav-tabs" id="courseTab" role="tablist">
                        <li class="nav-item" role="presentation">
                           <button class="nav-link active" id="grid-tab" data-bs-toggle="tab" data-bs-target="#grid" type="button" role="tab" aria-controls="grid" aria-selected="true">
                           <svg class="grid" viewBox="0 0 24 24">
                              <rect x="3" y="3" class="st0" width="7" height="7"/>
                              <rect x="14" y="3" class="st0" width="7" height="7"/>
                              <rect x="14" y="14" class="st0" width="7" height="7"/>
                              <rect x="3" y="14" class="st0" width="7" height="7"/>
                              </svg>
                           </button>
                        </li>
                        <li class="nav-item" role="presentation">
                           <button class="nav-link list" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">
                           <svg class="list" viewBox="0 0 512 512">
                              <g>
                                 <path class="st0" d="M448,69H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,69,448,69z"/>
                                 <circle class="st0" cx="64" cy="100" r="31"/>
                                 <path class="st0" d="M448,225H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,225,448,225z"/>
                                 <circle class="st0" cx="64" cy="256" r="31"/>
                                 <path class="st0" d="M448,381H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,381,448,381z"/>
                                 <circle class="st0" cx="64" cy="412" r="31"/>
                              </g>
                              </svg>
                           </button>
                        </li>
                     </ul>
                  </div>
                  <div class="course__view">
                     <h4>Results : {{ total}}</h4>
                  </div>
               </div>
               <div class="course__sort d-flex justify-content-sm-end">
                  <div class="course__sort-inner">
                     <select v-model="order" @change='()=>{loadCourses()}'>
                        <option value="bestseller">Bestseller</option>
                        <option value="newer">Newer</option>
                        <option value="older">Older</option>
                     </select>
                  </div>
                  <button class="filter__button" @click="()=>{showFilter=true}"><i class="far fa-filter"></i></button>
               </div>
            </div>
            <div v-if='loading' style="height:200px;position:relative">
               <Loading loader="bars" :active="true" :is-full-page="false" :blur="null" color="#2b4eff" background-color="transparent" :z-index="1"/>
            </div>
            <div v-else class="course__tab-conent">
               <div class="tab-content" id="courseTabContent">
                  <div class="tab-pane fade show active" id="grid" role="tabpanel" aria-labelledby="grid-tab">
                     <CoursesCards :courses="courses"/>
                  </div>

                  <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                     <div class="row">

                        <div v-for="(course, index) in courses" :key="course._id" class="col-xxl-12">
                           <div class="course__item white-bg mb-30 fix">
                              <div class="row gx-0">
                                 <div class="col-xxl-4 col-xl-4 col-lg-4">
                                    <div class="course__thumb course__thumb-list w-img p-relative fix">
                                       <router-link :to="`/courses/${course._id}`">
                                          <img :src="$url+'/images/courses/'+course.thumbnailUrl" alt="">
                                       </router-link>
                                       <div v-if="course.label" class="course__tag">
                                          <a href="#" :class="colors[index % 7]">
                                             {{course.label}}
                                          </a>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-xxl-8 col-xl-8 col-lg-8">
                                    <div class="course__right">
                                       <div class="course__content course__content-4">
                                          <div class="course__meta d-flex align-items-center">
                                             <div class="course__lesson mr-20">
                                                <span><i class="far fa-book-alt"></i>
                                                {{course.lessons || 0}} Lesson</span>
                                             </div>
                                             <div class="course__rating">
                                                <span><i class="fas fa-star"></i>
                                                {{(Math.round((course.rating / course.numberOfRaters) * 100 ) /100) || 0}} ({{course.numberOfRaters}})</span>
                                             </div>
                                          </div>
                                          <h3 class="course__title">
                                            <router-link :to="`/courses/${course._id}`">
                                               {{course.name}}
                                            </router-link>
                                          </h3>
                                          <div class="course__teacher d-flex align-items-center">
                                             <div class="course__teacher-thumb mr-15">
                                                <img :src="$url+'/images/users/thumb/'+course.instructorId.profilePictureUrl" alt="">
                                             </div>
                                              <h6>
                                                <router-link :to="'/instructor-details/'+course.instructorId._id">{{course.instructorId.firstName + ' ' + course.instructorId.lastName}}</router-link>
                                             </h6>
                                          </div>
                                       </div>
                                       <div class="course__more course__more-2 course__more-3 d-flex justify-content-between align-items-center">
                                       <div class="course__status d-flex align-items-center">
                                          <span :class="colors[index % 7]">{{Math.max(course.price  - course.price * (course.discountPercent||0)/100, 0)}}JOD</span>
                                          <span v-if='course.discountPercent' class="old-price">{{(course.price)}}JOD</span>
                                       </div>
                                          <div class="course__btn">
                                             <router-link :to="'/courses/'+course._id" class="link-btn">
                                                Know Details
                                                <i class="far fa-arrow-right"></i>
                                                <i class="far fa-arrow-right"></i>
                                             </router-link>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>    
                     </div>
                  </div>
               </div>

               <!-- Pagination start-->
               <Pagination :pages="(Math.ceil(this.total/10))" :page="page" @page="changePage"/>
               <!-- Pagination end-->
            </div>
         </div>

         <!-- CourseRightSide start -->
         <CourseRightSide :categories="categories" :show='showFilter' @close="showFilter=false" @search="search"/>
         <!-- CourseRightSide end -->

      </div>
   </div>
</section>
</template>

<script>
import CourseRightSide from '../CourseSidebar/CourseRightSide.vue';
import Pagination from '../common/Pagination.vue';
import colors from '../../mixins/colors';
import api from '@/api'
import Loading from 'vue-loading-overlay';
import CoursesCards from './CoursesCards.vue'

export default {
   name:'CourseSidebarArea',
   mixins:[colors],
   props:['categories'],
   components:{
      CourseRightSide,
      Pagination,
      Loading,
      CoursesCards
   },
   data(){
      return {
         showFilter:false,
         loading:false,
         order:'bestseller',
         courses:[],
         total:0,
         page:0,
      }
   },
   methods:{
      changePage(page){
         this.page = page;
         let query = this.$route.query;
         this.loadCourses(query.categories, query.name)
      }, 
      search(categories, name, tags){
         console.log(categories, name, tags)
         this.$router.replace({path:'/courses', query:{
            name: name,
            categories: categories,
            tags:tags
         }})
         this.loadCourses(categories, name, tags)
      },
      loadCourses(categories = [], name = '', tags=[]){
         this.loading = true
         fetch(`${api.Url}/api/courses?` + new URLSearchParams({
            name: name,
            categories: categories,
            tags:tags,
            order: this.order,
            page: this.page
         }),{
            method:'GET',
         })
         .then(res => res.json())
         .then(res => {
            console.log(res)
            this.loading = false
            if (res.success){
               this.courses = res.courses
               this.total = res.total   
            }
         })
         .catch(err => {
            throw err
         })
      }
   },
   mounted(){
      //order
      if (this.$route.query.order)
         this.order = this.$route.query.order;

      //categories
      let ids = [];
      if (this.$route.query.categories)
         ids = this.$route.query.categories
      if (!Array.isArray(ids))
         ids = [ids];
      //name
      let name = this.$route.query.name;

      //tags
      let tags = [];
      if (this.$route.query.tags)
         tags = this.$route.query.tags
      if (!Array.isArray(tags))
         tags = [tags];


      this.loadCourses(ids, name, tags)
   },

};
</script>

