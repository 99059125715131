<template>
   <section class="category__area pt-120 pb-70">
            <div class="container">
               <div class="row align-items-end">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                     <div class="section__title-wrapper mb-45">
                        <h2 class="section__title"><span class="yellow-bg">Top Categories<img src="/img/shape/yellow-bg-2.png" alt=""></span>
                        </h2>
                        <p>Consistency and hard work are the key to success.</p>
                     </div>
                  </div>
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-4">
                     <div class="category__more mb-50 float-md-end fix">
                        <router-link to="/courses" class="link-btn">
                           View all Category
                           <i class="far fa-arrow-right"></i>
                           <i class="far fa-arrow-right"></i>
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div
                     class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                     v-for="(cat, idx) in categories"
                     :key="idx" 
                     
                  >
                     <router-link :to="'/courses?categories='+cat.id">
                        <div class="category__item mb-30 transition-3 d-flex align-items-center">
                           <div class="category__icon mr-30">
                              <img :src="`/img/categories/${cat.name}.svg`" onerror="this.style.visibility='hidden'" class="svg-image"/>
                           </div>
                           <div class="category__content">
                              <h4 class="category__title">{{cat.name}}</h4>
                           </div>
                        </div>
                     </router-link>
                  </div>
               </div>
               <div class="row justify-content-center flex-wrap">
                  <div
                     class="col col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-xs-6"
                  >
                     <router-link :to="'/courses?tags=63ffbb312ac24e12288018d9'">
                           <div class="university-tag__item d-flex justify-content-center align-items-center">
                              <div class="university-tag__icon mr-30">
                                 <img :src="`/img/university/university_of_jordan_logo.png`" onerror="this.style.visibility='hidden'" class="svg-image"/>
                              </div>
                              <div class="university-tag__content">
                                 <h4 class="university-tag__title">University of Jordan</h4>
                              </div>
                           </div>
                     </router-link>
                  </div>
                  <div
                     class="col col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-xs-6"
                  >
                     <router-link :to="'/courses?tags=63ffbd0a57ce72907f8475aa'">
                           <div class="university-tag__item d-flex justify-content-center align-items-center">
                              <div class="university-tag__icon mr-30">
                                 <img :src="`/img/university/balqaa_university_logo.png`" onerror="this.style.visibility='hidden'" class="svg-image"/>
                              </div>
                              <div class="university-tag__content">
                                 <h4 class="university-tag__title">Balqa'a University</h4>
                              </div>
                           </div>
                     </router-link>
                  </div>
                  <div
                     class="col col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-xs-6"
                  >
                     <router-link :to="'/courses?tags=64d02599e2d1c639f024f41c'">
                           <div class="university-tag__item d-flex justify-content-center align-items-center">
                              <div class="university-tag__icon mr-30">
                                 <img :src="`/img/university/just_logo.png`" onerror="this.style.visibility='hidden'" class="svg-image"/>
                              </div>
                              <div class="university-tag__content">
                                 <h4 class="university-tag__title">JUST University</h4>
                              </div>
                           </div>
                     </router-link>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
  name:'CategoryArea',
  props:['categories']
};
</script>