<template>
    <div class="tab-pane fade" id="materials" role="tabpanel" aria-labelledby="materials-tab">
        <div class="d-flex flex-column mb-40">
            <div 
                class="course__file-attachment d-flex flex-row align-items-end mr-20 mb-10"
                v-for="(file, i) in attachments"
                :key="i"
                :class="{'selected' : i == selected}"
                @click="openFile(file.url, i)"
            >
                <img :src="attachmentIcon(file.url)" alt="" class="my-1 mx-2">
                <div class="course__file-title m-1">
                    <div class="content">{{file.name}}</div>
                    <span class="tooltip" style="z-index:1">{{file.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

export default {
    name: "AttachmentsList",
    props:['attachments'],
    data(){
        return {
            selected : -1
        }
    },

    methods:{        
        attachmentIcon(filename){
            let ext = filename?.split('.')[1]
            if ( ext == 'pdf'){
                return "/img/attachment/pdf.png"
            } else if (ext == "doc" || ext == 'docx'){
                return "/img/attachment/document.png"
            } else if (ext == "ppt" || ext == 'pptx'){
                return "/img/attachment/powerpoint.png"
            } else if (ext == "png" || ext =="jpeg" || ext =="jpg"){
                return "/img/attachment/image.png"
            } else {
                return ""
            }
        },
        openFile(url, i){
            this.selected = i;
            const windowReference = window.open() //to support Safari
            fetch(`${api.Url}/api/attachments/${url}`,{
                method:'POST',
                headers: api.headers()
            })
            .then(res=>res.json())
            .then(res=> {
                if (res.success){
                    windowReference.location.assign(`${api.Url}/api/attachments/${url}?token=${res.token}`)
                } else {
                    windowReference.close();
                }
            })
            .catch(err => {
                throw err
            })
        }
    }
}
</script>

<style>

</style>