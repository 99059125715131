<template>
    <div class="row">
        <div v-for="(course, index) in courses" :key="course._id" 
            class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div class="course__item white-bg mb-30 fix">
                <div class="course__thumb w-img p-relative fix">
                    <router-link :to="`/courses/${course._id}`">
                    <img :src="$url+'/images/courses/'+course.thumbnailUrl" alt="">
                    </router-link>
                    <div v-if="course.label" class="course__tag">
                    <a href="#" :class="colors[index % 7]">{{course.label}}</a>
                    </div>
                </div>
                <div class="course__content">
                    <div class="course__meta d-flex align-items-center justify-content-between">
                    <div class="course__lesson">
                        <span><i class="far fa-book-alt"></i>
                        {{course.lessons}} Lesson</span>
                    </div>
                    <div class="course__rating">
                        <span><i class="fas fa-star"></i>
                        {{(Math.round((course.rating / course.numberOfRaters) * 100 ) /100) || 0}} ({{course.numberOfRaters}})</span>
                    </div>
                    </div>
                    <h3 class="course__title">
                    <router-link :to="`/courses/${course._id}`">{{course.name}}</router-link>
                    </h3>
                    <div class="course__teacher d-flex align-items-center">
                    <div class="course__teacher-thumb mr-15">
                        <img :src="$url+'/images/users/thumb/'+course.instructorId.profilePictureUrl" alt="">
                    </div>
                    <h6><router-link :to="'/instructor-details/'+course.instructorId._id">{{course.instructorId.firstName + ' ' + course.instructorId.lastName}}</router-link></h6>
                    </div>
                </div>
                <div class="course__more d-flex justify-content-between align-items-center">
                    <div class="course__status d-flex align-items-center">
                    <span :class="colors[index % 7]">{{Math.max(course.price  - course.price * (course.discountPercent||0)/100, 0)}}JOD</span>
                    <span v-if='course.discountPercent' class="old-price">{{(course.price)}}JOD</span>
                    </div>
                    <div class="course__btn">
                    <router-link :to="'/courses/'+course._id" class="link-btn">
                        Know Details
                        <i class="far fa-arrow-right"></i>
                        <i class="far fa-arrow-right"></i>
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import colors from '../../mixins/colors';

export default {
    name:'CoursesCards',
    props:['courses'],
    mixins:[colors]
}
</script>
