<template>
  <SignUpArea/>
</template>

<script>
import SignUpArea from '../components/SignUp/SignUpArea.vue';

export default {
  name:'SignUp',
  components:{
      SignUpArea,
  },
  props:['user'],
  //this way we cover two cases 1) directly going to signup or sign in 2) using router link
  watch:{
    user(newVal){
      if (newVal){
        this.$router.replace({name:"Dashboard"})
      }
    }
  },
  mounted(){
    if (this.user)
      this.$router.replace({name:"Dashboard"})
  }
}
</script>

