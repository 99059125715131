// isNotCompletedLogin
//Banned 

function unauthenticatedWhileWatching(){
    this.$root.logout();
    this.$router.push({name: "Login"})
}


let errors = {
    unauthenticatedWhileWatching: unauthenticatedWhileWatching,
}


module.exports = errors;